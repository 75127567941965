import axios from "axios";
import { API_URL } from "../constants/api";
import { useQuery } from "react-query";

const APIURL = axios.create({
  baseURL: API_URL,
});

const GeneralInformationAPI = async (uuid, lang) => {
  return await APIURL.get(`/${uuid}/hospital-general-info`, {
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      lang: lang,
    },
  });
};
export const ShowGeneralInformationAPI = (uuid, lang) => {
  return useQuery(`show_general-information-${lang}`, () =>
    GeneralInformationAPI(uuid, lang)
  );
};

const HospitalMyFeedbackAPI = async (uuid, lang) => {
  return await APIURL.get(`/${uuid}/hospital-my-feedback`, {
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      lang: lang,
    },
  });
};
export const ShowHospitalMyFeedbackAPI = (uuid, lang) => {
  return useQuery(`hospital-my-feedback-${lang}`, () =>
    HospitalMyFeedbackAPI(uuid, lang)
  );
};


const HospitalMyStLukekAPI = async (uuid, lang) => {
  return await APIURL.get(`/${uuid}/hospital-my-st-luke`, {
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      lang: lang,
    },
  });
};
export const ShowHospitalMyStLukekAPI = (uuid, lang) => {
  return useQuery(`hospital-my-st-luke-${lang}`, () =>
    HospitalMyStLukekAPI(uuid, lang)
  );
};

const HospitalOutpatientInfoAPI = async (uuid, lang) => {
  return await APIURL.get(`/${uuid}/hospital-outpatient-info`, {
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      lang: lang,
    },
  });
};
export const ShowHospitalOutpatientInfoAPI = (uuid, lang) => {
  return useQuery(`hospital-outpatient-info-${lang}`, () =>
    HospitalOutpatientInfoAPI(uuid, lang)
  );
};


