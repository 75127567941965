import React, { useState } from "react";
import { Button, Form } from "react-bootstrap";

const ExtraOptions = ({
	extrasWithOptions,
	onOptionChange,
	updateOptionCount,
	path = [],
}) => {
	const isParentExtra = path.length === 0; // Parent extras have an empty path
	const handleInputChange = (e, extra, optionPath) => {
		const newCheckedState = e.target.checked;
		onOptionChange(optionPath, newCheckedState);
	};
	// <hr />;

	return extrasWithOptions.map((extra, extraIndex) => {
		const uniquePath = [...path, extraIndex]; // Path to ensure uniqueness
		return (
			<div key={extra.extra_id} className="p-0  rounded-lg  ">
				{/* <pre>{JSON.stringify(extrasWithOptions, null, 2)}</pre> */}
				<div>
					<p
						className={`extras-heading mb-0 m-0 pt-o text ${
							isParentExtra ? "parent-extra" : "sub-extra"
						}`}
						style={{ cursor: "pointer" }}
					>
						{extra.name} {}{" "}
						<span className="required">
							{extra.is_required ? "required" : ""}
						</span>
					</p>
				</div>
				<div className="">
					{extra.option.map((option, optionIndex) => {
						const selectedCount = extra.option.filter(
							(option) => option.checked
						).length;
						const optionPath = [...path, extraIndex, optionIndex]; // Track unique path
						const uniqueExtraId = `${extra.extra_id}-${path.join(
							"-"
						)}`;

						const isChecked = option.checked; // Controls expansion
						return (
							<>
								<div className="option-container">
									<div
										key={`${uniqueExtraId}-${option.id}`}
										className={`d-flex justify-content-between align-items-center  p-2  rounded price-container ${
											path.length === 0
												? "parent-option"
												: "sub-option"
										}`}
										style={{ cursor: "pointer" }}
									>
										<div className="extras-item">
											<p
												className={` item-text mb-0  ${
													path.length < 1 &&
													option.extrasWithOptions
														?.length > 0
														? "parent-option"
														: "sub-option"
												}`}
											>
												{option.name}
											</p>
										</div>
										<div className="res_options">
											{option.max_qty > 1 &&
												option.checked && (
													<div className="count-order-amount">
														<Button
															className="small"
															onClick={(e) =>
																updateOptionCount(
																	optionPath,
																	-1
																)
															}
														>
															<i className="fa fa-minus"></i>
														</Button>
														<p>{option.quantity}</p>
														<Button
															className=" small"
															onClick={(e) =>
																updateOptionCount(
																	optionPath,
																	+1
																)
															}
														>
															<i className="fa fa-plus"></i>
														</Button>
													</div>
												)}
											{option.price > 0 && (
												<p className="mx-2">
													+{option.price} AED
												</p>
											)}
											<Form.Check
												className="item-check"
												type={
													extra.extra_type_name ===
													"checkbox"
														? "checkbox"
														: "radio"
												}
												name={
													extra.extra_type_name ===
													"radio"
														? `group-${uniqueExtraId}`
														: undefined
												}
												checked={option.checked}
												onChange={(e) =>
													handleInputChange(
														e,
														extra,
														optionPath
													)
												}
												disabled={
													!option.checked &&
													extra.extra_type_name ===
														"checkbox" &&
													selectedCount ===
														extra.max_options
												}
											/>
										</div>
									</div>

									{isChecked &&
										option.extrasWithOptions?.length >
											0 && (
											<div className="mx-auto">
												<ExtraOptions
													extrasWithOptions={
														option.extrasWithOptions
													}
													onOptionChange={
														onOptionChange
													}
													updateOptionCount={
														updateOptionCount
													}
													path={optionPath}
												/>
												{/* Add a <br /> after the first extra */}
												<hr className="custom-hr" />
											</div>
										)}
								</div>
							</>
						);
					})}
				</div>
				{/* <hr />  */}
			</div>
		);
	});
};

export default ExtraOptions;
