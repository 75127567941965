import React from "react";
import { useTranslation } from "react-i18next";
function Clientinfo({ className, roomNumber, orderNumber, orderedAt }) {
  const currentDate = new Date();
  const { t, i18n } = useTranslation();
  // Define arrays for days and months
  const daysOfWeek = [
    "Sunday",
    "Monday",
    "Tuesday",
    "Wednesday",
    "Thursday",
    "Friday",
    "Saturday",
  ];
  const months = [
    "Jan",
    "Feb",
    "Mar",
    "Apr",
    "May",
    "Jun",
    "Jul",
    "Aug",
    "Sep",
    "Oct",
    "Nov",
    "Dec",
  ];

  const dayOfWeek = daysOfWeek[currentDate.getDay()];
  const month = months[currentDate.getMonth()];
  const day = currentDate.getDate();
  const year = currentDate.getFullYear();
  const hours = currentDate.getHours();
  const minutes = currentDate.getMinutes();
  const type = localStorage.getItem("type")
  return (
    <>
      <div className="full-div padd-div">
        <ul className={className ? "your-order-list spa" : "your-order-list"}>
          <li>
            <p>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="17.307"
                height="12.362"
                viewBox="0 0 17.307 12.362"
                className="mr-2"
              >
                <g
                  id="Icon_ionic-ios-card"
                  data-name="Icon ionic-ios-card"
                  transform="translate(-2.25 -6.75)"
                >
                  <path
                    id="Path_313"
                    data-name="Path 313"
                    d="M19.557,7.986A1.24,1.24,0,0,0,18.32,6.75H3.486A1.24,1.24,0,0,0,2.25,7.986V9.84H19.557Z"
                    fill="#006390"
                  ></path>
                  <path
                    id="Path_314"
                    data-name="Path 314"
                    d="M2.25,21.931a1.24,1.24,0,0,0,1.236,1.236H18.32a1.24,1.24,0,0,0,1.236-1.236V15.75H2.25Zm5.486-1.854h6.335a.542.542,0,0,1,.541.541h0a.542.542,0,0,1-.541.541H7.736a.542.542,0,0,1-.541-.541h0A.542.542,0,0,1,7.736,20.077Zm-2.781,0h.464a.542.542,0,0,1,.541.541h0a.542.542,0,0,1-.541.541H4.954a.542.542,0,0,1-.541-.541h0A.542.542,0,0,1,4.954,20.077Z"
                    transform="translate(0 -4.055)"
                    fill="#006390"
                  ></path>
                </g>
              </svg>
              {type == "in_dining" ? t("Table  Number") : t("Room Number")} :
            </p>{" "}
            <span>{roomNumber ? roomNumber : ""}</span>
          </li>
          <li>
            <p>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="12.076"
                height="16.103"
                className="mr-2"
                viewBox="0 0 12.076 16.103"
              >
                <path
                  id="Icon_awesome-receipt"
                  data-name="Icon awesome-receipt"
                  d="M11.271.1,10.063,1.508,8.353.1a.5.5,0,0,0-.6,0L6.038,1.508,4.327.1a.5.5,0,0,0-.6,0L2.013,1.508.805.1A.5.5,0,0,0,0,.5V15.6a.5.5,0,0,0,.805.4l1.208-1.409L3.723,16a.5.5,0,0,0,.6,0l1.711-1.409L7.749,16a.5.5,0,0,0,.6,0l1.711-1.409L11.271,16a.5.5,0,0,0,.805-.4V.5a.5.5,0,0,0-.805-.4ZM10.063,11.32a.252.252,0,0,1-.252.252H2.264a.252.252,0,0,1-.252-.252v-.5a.252.252,0,0,1,.252-.252H9.812a.252.252,0,0,1,.252.252Zm0-3.019a.252.252,0,0,1-.252.252H2.264A.252.252,0,0,1,2.013,8.3V7.8a.252.252,0,0,1,.252-.252H9.812a.252.252,0,0,1,.252.252Zm0-3.019a.252.252,0,0,1-.252.252H2.264a.252.252,0,0,1-.252-.252v-.5a.252.252,0,0,1,.252-.252H9.812a.252.252,0,0,1,.252.252Z"
                  transform="translate(0 0.002)"
                  fill="#006390"
                ></path>
              </svg>{" "}
              {t("Your Order Number")} :
            </p>{" "}
            <span>{orderNumber ? orderNumber : ""}</span>
          </li>
          <li>
            <p>
              <svg
                id="Layer_2"
                data-name="Layer 2"
                xmlns="http://www.w3.org/2000/svg"
                width="16"
                height="16"
                viewBox="0 0 16 16"
                className="mr-1"
              >
                <g id="invisible_box" data-name="invisible box">
                  <rect
                    id="Rectangle_345"
                    data-name="Rectangle 345"
                    width="16"
                    height="16"
                    fill="none"
                  ></rect>
                </g>
                <g
                  id="icons_Q2"
                  data-name="icons Q2"
                  transform="translate(0.667 0.659)"
                >
                  <path
                    id="Path_306"
                    data-name="Path 306"
                    d="M32,28H30.667a.667.667,0,0,0-.667.667V30a.667.667,0,0,0,.667.667H32A.667.667,0,0,0,32.667,30V28.667A.667.667,0,0,0,32,28Zm0,0H30.667a.667.667,0,0,0-.667.667V30a.667.667,0,0,0,.667.667H32A.667.667,0,0,0,32.667,30V28.667A.667.667,0,0,0,32,28Z"
                    transform="translate(-20.667 -19.326)"
                    fill="#006390"
                  ></path>
                  <path
                    id="Path_307"
                    data-name="Path 307"
                    d="M16,3.985H13v-1.3a.7.7,0,0,0-.567-.7.667.667,0,0,0-.767.667V3.985H7v-1.3a.7.7,0,0,0-.567-.7.667.667,0,0,0-.767.667V3.985h-3A.667.667,0,0,0,2,4.652V15.318a.667.667,0,0,0,.667.667H16a.667.667,0,0,0,.667-.667V4.652A.667.667,0,0,0,16,3.985Zm-.667,10.667h-12V7.985h12Zm0-8h-12V5.318h12Z"
                    transform="translate(-2 -1.977)"
                    fill="#006390"
                  ></path>
                  <rect
                    id="Rectangle_346"
                    data-name="Rectangle 346"
                    width="3"
                    height="2"
                    rx="1"
                    transform="translate(9.333 9.341)"
                    fill="#006390"
                  ></rect>
                </g>
              </svg>{" "}
              {t("Date & Time")} :
            </p>{" "}
            {orderedAt ? (
              <span> {orderedAt}</span>
            ) : className ? (
              <span>
                <span>{month}</span> <span>{day}</span>
                {", "}
                <span>{year}</span> <span>{hours}</span> {": "}
                <span>{minutes}</span>
              </span>
            ) : (
              <>
                <span></span> <span></span>
              </>
            )}
          </li>
        </ul>
      </div>
    </>
  );
}
export default Clientinfo;
