import React, {
	useState,
	useRef,
	useEffect,
	useLayoutEffect,
	useContext,
} from "react";
import HeaderInner from "../../Components/HeaderInner";
import Post1 from "../../assets/images/In-Room-Dining/Restaurant-2.png";
import AddCartPost from "../../Components/AddCartPost";
import { Button, Modal } from "react-bootstrap";
import ViewCartModal from "../../Components/ViewCartModal";
import ReactGA from "react-ga4";

import {
	Link,
	useLocation,
	useNavigate,
	useNavigation,
	useParams,
} from "react-router-dom";
import useDraggableScroll from "use-draggable-scroll";
import Carosle from "../../Components/Carosle";
import { useCartStore } from "../../store/useStore";
import SpaLoader from "../../Components/Loaders/SpaLoader";
import ErrorPage from "../../Components/ErrorPage";
import {
	RestaurantSubMenuAPI,
	ShowComboMealsItems,
} from "../../API/In-Room-DiningAPI";
import ViewCart from "../../Components/ViewCart";
import { useInfiniteQuery } from "react-query";
import Search from "../../Components/Search";
import ComboMeal from "../../Components/ComboMeal";
import Title from "../../new-components/title/Title";
import BannerHome from "../../new-components/banner-home/BannerHome";
import { ThemeContext } from "../../context/ThemeContext";
// : r_uuid /: c_id
function ComboMealsItems() {
	const params = useParams();
	const [show, setShow] = useState(false);
	const [selected, setSelected] = useState(params.c_id);
	const lang = localStorage.getItem("lang");
	// const new_design = localStorage.getItem("new_design");
	// const theme = new_design == "true";
	const { theme } = useContext(ThemeContext);
	// const [headerName, setHeaderName] = useState();
	// const [images, setImages] = useState([]);
	// const [categories, setCategories] = useState([]);
	// const [error, setError] = useState("");

	const navigate = useNavigate();
	const ref = useRef(null);
	const urRef = useRef(null);
	const { onMouseDown } = useDraggableScroll(ref);
	// const [restaurantItems, setRestaurantItems] = useState();
	const location = useLocation();
	const rawPath = location.pathname;
	const room_uuid = rawPath.split("/")[1];
	const r_uuid = rawPath.split("RestaurantSubMenu/")[1];
	const handleClose = () => setShow(false);
	const handleShow = () => setShow(true);

	// let restaurants;
	//
	let restaurant_id;
	let isOffCategory;
	let restaurant_is_closed;
	let headerName;
	let terms_and_conditions;
	let images;
	let isVideo;
	let bgColor;
	let restaurant;
	let restaurantID;
	let combo_meals;
	let currency;
	const restaurant_uuid = params.restaurant_uuid;

	const { data, isLoading, error } = ShowComboMealsItems(
		room_uuid,
		restaurant_uuid,
		lang
	);

	const [hideBtn, setHideBtn] = useState(true);

	if (!isLoading) {
		// console.log("stata", data.data.status);
		if (data.data.status == false) {
			navigate(`/${room_uuid}/qr-scan`);
		} else if (!error) {
			console.log("data_comp", data);
			restaurant = data.data.data;
			// restaurantCategories = restaurant.categories;
			// has_combo_meals = restaurant.has_combo_meals;
			terms_and_conditions = restaurant.restaurant.terms_and_conditions;

			isVideo = restaurant.restaurant.real_video ? true : false;
			if (restaurant.restaurant.real_video) {
				if (restaurant?.banners?.length > 0) {
					images = [
						{ image: restaurant.restaurant.real_video },
						...restaurant.banners,
					];
				}
			} else {
				if (restaurant?.banners?.length > 0) {
					images = restaurant.banners;
				}
			}
			bgColor = data.data.data.header_color;
			headerName = data.data.data.center_header;
			combo_meals = data.data.data.combo_meals;
			currency = data.data.data.currency;
			restaurantID = data.data.data.restaurant.id;

			// window.scrollTo({
			//   top: 0,
			//   left: 0,
			// });
		}
	}

	console.log("currency", currency);

	let h_ref = useRef(null);
	let divRef = useRef(null);
	let slider_ref = useRef(null);
	let heightDiv;
	let TopDiv;

	useEffect(() => {
		if (ref.current) {
			// console.log("ref.current", ref.current.children);

			const lists = ref.current.children;
			// console.log("ref", ref.current.children);
			// ref.current.children;
			Object.values(lists).map((list, idx) => {
				if (list.classList.contains("active")) {
					const itemWidth = list.offsetWidth;
					const scrollPosition = idx * itemWidth;

					ref.current.scrollTo({
						left: scrollPosition,
						behavior: "smooth",
					});
					//     // Your logic when the "active" class is present
					//     // console.log('The first child has the "active" class');
				}
			});
		}
	}, [data]);
	// useLayoutEffect(() => {
	//   if (h_ref.current) {
	//     console.log(
	//       "data?.pages[0].data?.data?.items?.data.length",
	//       data?.pages[0].data?.data?.items?.data.length
	//     );
	//     if (data?.pages[0].data?.data?.items?.data.length > 4) {
	//       const divAnimate = h_ref.current.getBoundingClientRect().top - 70;
	//       console.log("divAnimate", divAnimate);
	//       const onScroll = () => {
	//         if (divAnimate < window.scrollY) {
	//           console.log("ok");
	//           h_ref.current.classList.add("fixedSearch");
	//         } else {
	//           h_ref.current.classList.remove("fixedSearch");
	//         }
	//       };
	//       window.addEventListener("scroll", onScroll);
	//       return () => window.removeEventListener("scroll", onScroll);
	//     }
	//   }
	// }, [data]);
	useEffect(() => {
		window.scrollTo({
			top: 0,
			left: 0,
		});
	}, []);

	const handelNav = (id) => {
		window.scrollTo({
			top: 0,
			left: 0,
		});
		// console.log("iddd", id);
		navigate(`/${room_uuid}/RestaurantSubCat/${params.r_uuid}/${id}`);
	};
	const showData = () => {
		if (isLoading) {
			return <SpaLoader />;
		}
		if (error) {
			return <ErrorPage des={error.message} />;
		}
		return (
			<main
				className="bg-white pad-top-0"
				style={{ marginBottom: "100px" }}
			>
				{images?.length > 0 && (
					<header className="img-header m-0" ref={slider_ref}>
						{theme ? (
							<BannerHome
								className={"bot_rad"}
								headerImages={images}
								isVideo={isVideo}
								isOverllay={true}
							/>
						) : (
							<Carosle headerImages={images} isVideo={isVideo} />
						)}
					</header>
				)}
				<div ref={h_ref}>
					<Search
						placeholder="Search for Dishes, Drinks ..."
						room_uuid={room_uuid}
						category="Restaurant"
					/>
					{terms_and_conditions != "" &&
						(terms_and_conditions != undefined ? (
							<>
								{/* <p className="text-center">Terms & Conditions</p> */}
								<div
									className="mx-4 divhtml"
									dangerouslySetInnerHTML={{
										__html: terms_and_conditions,
									}}
								/>
							</>
						) : null)}

					<ul
						className="inline-list search"
						ref={ref}
						onMouseDown={onMouseDown}
						id=""
					>
						{/* {data?.pages[0]?.data?.data?.categories?.map((category, idx) => {
              // console.log("category", category.count_sub_categories);
              return category.count_sub_categories == 0 ? (
                <li
                  className={selected == category?.id && "active"}
                  onMouseDown={() => {
                    setSelected(category?.id);
                    window.scrollTo({
                      top: 0,
                      left: 0,
                    });
                    ReactGA.event({
                      category: "Select category in restaurant",
                      action: "Click",
                      value: `category name: ${category?.name}}`,
                    });

                    // scrollToItem(idx);
                  }}
                  ref={urRef}
                  key={idx}
                >
                  <Button>{category?.name}</Button>
                </li>
              ) : (
                <li
                  className={selected == category?.id && "active"}
                  key={idx}
                  onClick={(id) => {
                    handelNav(category?.id);
                  }}
                >
                  <Button>{category?.name}</Button>
                </li>
              );
            })} */}
					</ul>
				</div>
				<div ref={divRef}>
					{combo_meals?.map((meal) => {
						return (
							<ComboMeal
								item={meal}
								restaurantId={restaurantID}
								hideBtn={hideBtn}
								setHideBtn={setHideBtn}
								currency={currency}
							/>
						);
					})}
				</div>

				<ViewCart room_uuid={room_uuid} />
			</main>
		);
	};
	return (
		<div className={theme ? "food" : ""}>
			{theme ? (
				<Title title={headerName} />
			) : (
				<HeaderInner headerName={headerName} bgColor={bgColor} />
			)}

			{showData()}
			<Modal
				show={show}
				size="lg"
				onHide={handleClose}
				centered={false}
				className="view-cart-modal"
			>
				<Modal.Body className={`${lang == "ar" ? "ar_lang" : ""}`}>
					<Button className="close-btn" onClick={handleClose}>
						<i className="fa fa-times-circle-o"></i>
					</Button>
					<ViewCartModal />
				</Modal.Body>
			</Modal>
		</div>
	);
}
export default ComboMealsItems;
