import axios from "axios";
import { API_URL } from "../constants/api";
import { useQuery } from "react-query";

const APIURL = axios.create({
  baseURL: API_URL,
});

// const getLang = () => {
//   return localStorage.getItem("lang");
// };
const HomePage = async (uuid, lang) => {
  return await APIURL.get(`/${uuid}/qr-code`, {
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      lang: lang,
    },
  });
};
export const ShowHomeMenu = (uuid, lang) => {
  return useQuery(`home_menu-${lang}`, () => HomePage(uuid, lang));
};
const CheckUUID = async (uuid, lang) => {
  return await APIURL.get(`/${uuid}`, {
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      lang: lang,
    },
  });
};
export const ShowCheckUUID = (uuid) => {
  return useQuery(`CheckUUID`, () => CheckUUID(uuid));
};
