import React, { useContext, useEffect, useRef, useState } from "react";
import Iframe from "react-iframe";
import Title from "../../new-components/title/Title";
import HeaderInner from "../../Components/HeaderInner";
import NewFooter from "../../NewFooter";
import { useLocation } from "react-router-dom";
import { t } from "i18next";
import { ThemeContext } from "../../context/ThemeContext";

function HospitalMyStLukeItem() {
  const [urlParam, setUrlParam] = useState("");
  const [title, setTitle] = useState("");
  const location = useLocation();
  const metadata = location.state?.metadata;

  useEffect(() => {
    const queryParams = new URLSearchParams(window.location.search);
    const url = queryParams.get("url");
    const title_ = queryParams.get("title");
    setUrlParam(url);
    setTitle(title_);
  }, []);

  console.log("urlParam", urlParam);


  // const new_design = localStorage.getItem("new_design");
  // const theme = new_design == "true";
  const { theme } = useContext(ThemeContext);
  const parseHTMLToText = (html) => {
    const tempElement = document.createElement('div');
    tempElement.innerHTML = html;
    return tempElement.textContent || tempElement.innerText || "";
  };


  useEffect(() => {
    window.scrollTo({
      top: 0,
      left: 0,
    });
  }, []);

  // useEffect(() => {
  //   const getData = async () => {
  //     setLaoding(true);
  //     axios
  //       .get(`/${room_uuid}/location`)
  //       .then((res) => {
  //         if (res.data.status === false) {
  //           navigate(`/${room_uuid}/qr-scan`);
  //         } else {
  //           const data = res.data.data;
  //           setDataInfo(res.data.data);
  //           setLaoding(false);
  //         }
  //       })
  //       .catch((err) => {
  //         if (err?.response.status == 404) {
  //           navigate(`/not-found`);
  //         }
  //       });
  //   };
  //   getData();
  // }, []);
  // console.log("urlParam", urlParam == "https://d1xx0o2taatuie.cloudfront.net/GF MAP.jpg");
  if (urlParam == "https://d1xx0o2taatuie.cloudfront.net/GF MAP.jpg") {
    console.log("encodeURI(item.image)", encodeURI(urlParam));

  }
  // <HeaderInner headerName={"Taxi booking"} />
  return (
    <div className={theme ? "food" : ""}>
      {theme ? (
        <Title title={title} />
      ) : (
        <HeaderInner headerName={title} />
      )}
      <main className="home-main bg-white back-white  pad-top-0 taxi_book thing_to hos">
        {metadata &&
          <div className="div-des">
            <p>{t(`${parseHTMLToText(metadata)}`)}</p>
          </div>
        }
        <Iframe
          url={urlParam}

          // url="https://www.wikipedia.org/"
          width="100%"
          // height="80vh"
          id=""
          className="iframe_layar"
          display="block"
          sandbox="allow-same-origin allow-scripts"
        // position="relative"
        />


      </main>
      {theme && <NewFooter />}
    </div>
  );
}
export default HospitalMyStLukeItem;
