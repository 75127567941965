import React from "react";
import { ShowAllFlowers } from "../API/FlowersAPI";
import PharmacyLoader from "./Loaders/PharmacyLoader";
import { Link, useNavigate } from "react-router-dom";
import Fade from "react-reveal/Fade";
import { Zoom } from "react-reveal";

const FlowersComponent = ({ room_uuid, fl_uuid, fl_id, theme }) => {
  const lang = localStorage.getItem("lang");
  let flowers;
  let flowerCategories;
  let bgColor;
  let color;
  let text;
  let terms_and_conditions;
  const navigate = useNavigate();
  const { data, isLoading, error } = ShowAllFlowers(room_uuid, fl_uuid, lang);
  if (!isLoading) {
    if (error) {
      if (error?.response.status == 404) {
        navigate(`/not-found`);
      }
    } else {
      if (data.data.status == false) {
        navigate(`/${room_uuid}/qr-scan`);
      } else {
        flowers = data.data.data;
        flowerCategories = flowers.categories;
        terms_and_conditions = flowers?.florist.terms_and_conditions;
        color = flowers.color;
        bgColor = flowers.header_color;
        text = flowers.title;
        // console.log("flowerCategories", flowers);

        if (flowerCategories?.length == 1) {
          navigate(
            `/${room_uuid}/FlowersSubMenu/${fl_id}/${fl_uuid}/${flowerCategories[0].id}`,
            { replace: true }
          );
        }

      }

    }
  }

  return (
    <>
      {terms_and_conditions == "" &&
        (terms_and_conditions != undefined ? (
          <>
            {/* <p className="text-center">Terms & Conditions</p> */}
            <div
              className="mx-4 divhtml"
              dangerouslySetInnerHTML={{
                __html: terms_and_conditions,
              }}
            />
          </>
        ) : null)}
      <ul className="display-list  suber_market_cat phamacy  three-items v3 v2">
        {isLoading ? (
          <PharmacyLoader />
        ) : (
          flowerCategories?.length > 1 &&
          flowerCategories.map((flower, i) => {
            return (
              <>
                <li key={i}>
                  <Zoom>
                    <Link
                      to={
                        flower?.active_sub_categories.length > 0
                          ? `/${room_uuid}/FlowersSubCategory/${fl_uuid}/${flower.id}`
                          : `/${room_uuid}/FlowersSubMenu/${fl_id}/${fl_uuid}/${flower.id}`
                      }

                      state={{ theme: theme }}
                    >
                      <img src={flower.image} alt="" />
                      <div></div>
                      {/* <div className="bg-layer v2"></div> */}
                      <p className="text-black">{flower.name}</p>
                    </Link>
                  </Zoom>
                </li>
              </>
            );
          })
        )}
      </ul>
    </>
  );
};

export default FlowersComponent;
