import React from "react";
import HomeMenu from "./HomeMenu";
import NewHome from "./NewHome";

const QrHome = () => {
  const new_design = localStorage.getItem("new_design");
  if (new_design == "true") {
    return <NewHome />;
  } else {
    return <HomeMenu />;
  }
};

export default QrHome;
