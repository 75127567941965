import React from "react";
import { useTranslation } from "react-i18next";
import { useLocation, useNavigate } from "react-router-dom";
import ReactGA from "react-ga4";

const Search = ({ placeholder, room_uuid, category, redirect, className }) => {
  const { t, i18n } = useTranslation();
  const navigate = useNavigate();
  const location = useLocation();
  console.log("location", location.pathname);

  return (
    <div
      className={`Search-bar less  ${className ? className : "mt-30"}  `}
      onClick={() => {
        navigate(
          `/${room_uuid}/${redirect ? redirect : "SearchPage"}?${category}`
        );
        ReactGA.event({
          category: "Click on the search field",
          action: "Click",
          value: `Click on the search field`,
        });
      }}
    >
      <input
        className="form-control"
        type="search"
        placeholder={t(placeholder)}
        readOnly
      />
      <i class="fa fa-search" aria-hidden="true"></i>
    </div>
  );
};

export default Search;
