import React, { useEffect } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";

import { ShowFlowers } from "../../API/FlowersAPI";
import SpaLoader from "../../Components/Loaders/SpaLoader";
import Title from "../../new-components/title/Title";
import Overllay from "../../assets/new_imgs/Rectangle 1072.png";

function FlowersService() {
  const location = useLocation();
  const rawPath = location.pathname;
  const room_uuid = rawPath.split("/")[1];
  const lang = localStorage.getItem("lang");

  const navigate = useNavigate();
  let florist = { id: "", uuid: "" };
  let flowers;
  let allFlowers;
  let text;
  const { data, isLoading, error } = ShowFlowers(room_uuid, lang);
  if (!isLoading) {
    if (error) {
      if (error?.response.status === 404) {
        navigate(`/not-found`);
      }
    } else {
      if (data.data.status === false) {
        navigate(`/${room_uuid}/qr-scan`);
      } else {
        flowers = data.data.data;
        if (flowers.florists.length > 0) {
          florist.id = flowers?.florists[0]?.id;
          florist.uuid = flowers?.florists[0]?.uuid;
          allFlowers = flowers.florists;
          text = flowers.title;
        }
      }
      if (allFlowers?.length === 1) {
        navigate(
          `/${room_uuid}/flower/category/${allFlowers[0].uuid}/id/${allFlowers[0].id}`,
          { replace: true, state: { theme: true } }
        );
      }
    }
  }
  useEffect(() => {
    window.scrollTo({
      top: 0,
      left: 0,
    });
  }, []);
  return (
    <div className="food">
      <Title title={text} />
      <div className="box_food">
        {isLoading ? (
          <SpaLoader />
        ) : allFlowers?.length > 1 ? (
          allFlowers?.map((flower, idx) => {
            return (
              <div key={idx}>
                <Link
                  key={idx}
                  to={`/${room_uuid}/flower/category/${flower.uuid}/id/${flower.id}`}
                  state={{ theme: true }}
                >
                  <img src={flower?.image} alt="" className="booking_img" />
                  <img src={Overllay} alt="" className="overlay" />
                  <div className="text_food">
                    <h5 className="">{flower.name}</h5>
                  </div>{" "}
                </Link>
              </div>
            );
          })
        ) : null}
      </div>
    </div>
  );
}
export default FlowersService;
