import axios from "axios";
import { API_URL } from "../constants/api";
import { useQuery } from "react-query";

const APIURL = axios.create({
  baseURL: API_URL,
});

const Concierge = async (uuid, lang) => {
  return await APIURL.get(`/${uuid}/concierge`, {
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      lang: lang,
    },
  });
};
export const ShowConcierge = (uuid, lang) => {
  return useQuery(`concierge-${lang}`, () => Concierge(uuid, lang));
};
const LuggagePickup = async (uuid, lang) => {
  return await APIURL.get(`/${uuid}/concierge/luggage-pickup`, {
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      lang: lang,
    },
  });
};
export const ShowLuggagePickup = (uuid, lang) => {
  return useQuery(`luggage-pickup-${lang}`, () => LuggagePickup(uuid, lang));
};

const Other = async (uuid, lang) => {
  return await APIURL.get(`/${uuid}/concierge/other`, {
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      lang: lang,
    },
  });
};
export const ShowOther = (uuid, lang) => {
  return useQuery(`other-${lang}`, () => Other(uuid, lang));
};
const WakeUpCall = async (uuid, lang) => {
  return await APIURL.get(`/${uuid}/concierge/wake-up-call`, {
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      lang: lang,
    },
  });
};
export const ShowWakeUpCall = (uuid, lang) => {
  return useQuery(`WakeUpCall-${lang}`, () => WakeUpCall(uuid, lang));
};
const Valet = async (uuid, lang) => {
  return await APIURL.get(`/${uuid}/concierge/valet`, {
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      lang: lang,
    },
  });
};
export const ShowValet = (uuid, lang) => {
  return useQuery(`valet'-${lang}`, () => Valet(uuid, lang));
};
const Shuttle = async (uuid, lang) => {
  return await APIURL.get(`/${uuid}/concierge/shuttle`, {
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      lang: lang,
    },
  });
};
export const ShowShuttle = (uuid, lang) => {
  return useQuery(`shuttle'-${lang}`, () => Shuttle(uuid, lang));
};
const Firestick = async (uuid, lang) => {
  return await APIURL.get(`/${uuid}/concierge/firestick`, {
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      lang: lang,
    },
  });
};
export const ShowFirestick = (uuid, lang) => {
  return useQuery(`firestick'-${lang}`, () => Firestick(uuid, lang));
};
const RequestMessenger = async (uuid, lang) => {
  return await APIURL.get(`/${uuid}/concierge/request-messenger`, {
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      lang: lang,
    },
  });
};
export const ShowRequestMessenger = (uuid, lang) => {
  return useQuery(`RequestMessenger'-${lang}`, () =>
    RequestMessenger(uuid, lang)
  );
};
