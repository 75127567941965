import React, { useEffect } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import SpaLoader from "../../Components/Loaders/SpaLoader";
import { ShowSupermarkets } from "../../API/Supermarket";
import Title from "../../new-components/title/Title";
import NewFooter from "../../NewFooter";
import Overllay from "../../assets/new_imgs/Rectangle 1072.png";

function SupermarketService() {
  const location = useLocation();
  const rawPath = location.pathname;
  const room_uuid = rawPath.split("/")[1];

  const navigate = useNavigate();
  const lang = localStorage.getItem("lang");

  let supermarket = { id: "", uuid: "" };
  let supermarkets_;
  let supermarkets;
  let text;
  const { data, isLoading, error } = ShowSupermarkets(room_uuid, lang);
  if (!isLoading) {
    if (error) {
      if (error?.response.status === 404) {
        navigate(`/not-found`);
      }
    } else {
      if (data.data.status === false) {
        navigate(`/${room_uuid}/qr-scan`);
      } else {
        supermarkets_ = data.data.data;
        supermarkets = supermarkets_.supermarkets;

        if (supermarkets.length > 0) {
          supermarket.id = supermarkets_.supermarkets[0].id;
          supermarket.uuid = supermarkets_.supermarkets[0].uuid;
        }
        text = supermarkets_.title;
        if (supermarkets?.length === 1) {
          navigate(`/${room_uuid}/supermarket/${supermarkets[0].uuid}`, {
            replace: true,
            state: { theme: true },
          });
        }
      }
    }
  }

  useEffect(() => {
    window.scrollTo({
      top: 0,
      left: 0,
    });
  }, []);
  const showData = () => {
    if (isLoading) {
      return <SpaLoader />;
    } else {
      return supermarkets?.length > 1
        ? supermarkets?.map((supermarket, idx) => {
          return (
            <div key={idx}>
              <Link
                key={idx}
                to={`/${room_uuid}/supermarket/${supermarket.uuid}`}
                state={{ theme: true }}
              >
                <img
                  src={supermarket?.image}
                  alt=""
                  className="booking_img"
                />
                <img src={Overllay} alt="" className="overlay" />
                <div className="text_food">
                  <h5 className="">{supermarket.name}</h5>
                </div>{" "}
              </Link>
            </div>
          );
        })
        : null;
    }
  };
  return (
    <div className="food">
      <Title title={text} />
      <div className="box_food">{showData()}</div>
      <NewFooter />
    </div>
  );
}
export default SupermarketService;
