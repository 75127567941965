import React, { useContext, useEffect, useRef, useState } from "react";
import { Link, useLocation, useNavigate, useParams } from "react-router-dom";
import iconbutton from "../assets/images/icons/icon-button.png";
import iconwhatsapp from "../assets/images/icons/whatsapp.svg";
import iconsearch from "../assets/images/icons/search.svg";
import ReactGA from "react-ga4";
import axios from "../axios";
import { ThemeContext } from "../context/ThemeContext";
function Footer() {
	const [isWhatsapp, setIsWhatsapp] = useState(false);
	const [whatsappNumber, setWhatsappNumber] = useState("");
	const [loading, setLoading] = useState(false);
	const [roomNumber, setRoomNumber] = useState();
	const [color, setColor] = useState();
	const [businessType, setBusinessType] = useState("");
	const [has_extra_services, setHas_extra_services] = useState(false);
	// const has_extra_services = localStorage.getItem("has_extra_services");
	const { setTheme } = useContext(ThemeContext);

	const location = useLocation();
	const rawPath = location.pathname;
	const room_uuid = rawPath.split("/")[1];
	const path = rawPath.split("/")[2];
	// const { room_uuid } = useParams();
	let active = false;
	if (path !== "menu" && path !== "SearchPage" && path !== "Whatsappchat") {
		active = true;
	}
	const navigate = useNavigate();
	const navigateTo = (location) => {
		navigate(location);
	};
	const colorRef = useRef(null);
	// console.log("location.pathname", location.pathname);

	useEffect(() => {
		const getData = () => {
			setLoading(true);
			// axios.get(`/648ed77e-da78-4987-9b86-de515659fc2c`).then((res) => {
			axios.get(`/${room_uuid}/qr-code?source=footer`).then((res) => {
				const data = res.data.data;
				// console.log("faaaaaaaaaaaaa", data?.room_number);
				localStorage.setItem("room_number", data?.room_number);
				// localStorage.setItem("logo", data?.logo);
				localStorage.setItem("currency", data?.currency || "AED");
				localStorage.setItem("tax_inclusive", data?.tax_inclusive);
				localStorage.setItem("show_prices", data?.show_prices);
				localStorage.setItem(
					"has_extra_services",
					data?.has_extra_services
				);
				localStorage.setItem("new_design", data?.use_new_design);
				setTheme(data?.use_new_design);

				localStorage.setItem("businessType", data.businessType);
				localStorage.setItem("countryCode", data?.country_code || "ae");
				localStorage.setItem("business_name", data?.business_name);

				setBusinessType(data.businessType);

				setRoomNumber(data?.room_number);

				setHas_extra_services(data?.show_prices);
				setIsWhatsapp(data?.whatsapp_enabled);
				setWhatsappNumber(data?.whatsapp_number);
				// color = data?.color
				setColor(data?.color);
				if (data?.color) {
					document.documentElement.style.setProperty(
						"--color",
						data?.color
					);
					document.documentElement.style.setProperty(
						"--color_t",
						`${data?.color}b3`
					);

					document.documentElement.style.setProperty(
						"--header_color",
						data?.header_color
					);
					// console.log("data", data);
				}
				// else {
				//   document.documentElement.style.setProperty("--color", "#006390");
				//   document.documentElement.style.setProperty("--color_t", `#00639099`);
				// }
				// colorRef.current.style.setProperty('--color', data?.color);
				setLoading(false);
				// setHeaderImages(data.header_images);
				// setLogo(data.logo);
				// setServices(data.activeServices);
				// console.log("MY FOOTER", data);
				const numbersArray = data?.room_number.match(/\d+/g).join("");
				let result;

				if (numbersArray) {
					if (numbersArray.length > 2) {
						result = parseInt(numbersArray.slice(0, 3), 10);
					} else {
						result = parseInt(numbersArray, 10);
					}
				}
				// console.log("RESULT", result, typeof result);
			});
		};
		getData();
	}, []);
	// console.log("color", color);

	return (
		<>
			{!loading && (
				<div className="footer notransition">
					<div>
						<Link
							to={`/${room_uuid}/menu`}
							className={path === "menu" ? "active-footer" : null}
							onClick={() => {
								ReactGA.event({
									category: "Navbar Link",
									action: "Click",
									value: "menu",
								});
							}}
						>
							<svg
								width="24"
								height="24"
								viewBox="0 0 24 24"
								fill={color}
								xmlns="http://www.w3.org/2000/svg"
							>
								<path
									fill-rule="evenodd"
									clip-rule="evenodd"
									d="M20.75 7C20.75 7.19891 20.671 7.38968 20.5303 7.53033C20.3897 7.67098 20.1989 7.75 20 7.75H4C3.80109 7.75 3.61032 7.67098 3.46967 7.53033C3.32902 7.38968 3.25 7.19891 3.25 7C3.25 6.80109 3.32902 6.61032 3.46967 6.46967C3.61032 6.32902 3.80109 6.25 4 6.25H20C20.1989 6.25 20.3897 6.32902 20.5303 6.46967C20.671 6.61032 20.75 6.80109 20.75 7ZM20.75 12C20.75 12.1989 20.671 12.3897 20.5303 12.5303C20.3897 12.671 20.1989 12.75 20 12.75H4C3.80109 12.75 3.61032 12.671 3.46967 12.5303C3.32902 12.3897 3.25 12.1989 3.25 12C3.25 11.8011 3.32902 11.6103 3.46967 11.4697C3.61032 11.329 3.80109 11.25 4 11.25H20C20.1989 11.25 20.3897 11.329 20.5303 11.4697C20.671 11.6103 20.75 11.8011 20.75 12ZM20.75 17C20.75 17.1989 20.671 17.3897 20.5303 17.5303C20.3897 17.671 20.1989 17.75 20 17.75H4C3.80109 17.75 3.61032 17.671 3.46967 17.5303C3.32902 17.3897 3.25 17.1989 3.25 17C3.25 16.8011 3.32902 16.6103 3.46967 16.4697C3.61032 16.329 3.80109 16.25 4 16.25H20C20.1989 16.25 20.3897 16.329 20.5303 16.4697C20.671 16.6103 20.75 16.8011 20.75 17Z"
									fill="#fff"
								></path>
							</svg>
						</Link>
					</div>

					<div
						onClick={() => {
							navigateTo(`/${room_uuid}/qr-code`);
							ReactGA.event({
								category: "Navbar Link",
								action: "Click",
								value: "home",
							});
						}}
					>
						<Link
							className={active ? "active-footer" : null}
							style={{ zIndex: "100", pointerEvents: "none" }}
						>
							<svg
								xmlns="http://www.w3.org/2000/svg"
								width="30.628"
								height="30.39"
								fill={color}
								viewBox="0 0 36.628 35.39"
							>
								<g
									id="Group_38366"
									data-name="Group 38366"
									transform="translate(-11338 422.324)"
								>
									<g
										id="home-alt-svgrepo-com"
										transform="translate(11347.528 -404.073)"
									>
										<rect
											id="Rectangle_805"
											data-name="Rectangle 805"
											className="cls-1f"
											width="18"
											height="16"
											transform="translate(0.346 -0.251)"
										></rect>
									</g>
									<g
										id="Group_38365"
										data-name="Group 38365"
										transform="translate(11338 -422.324)"
									>
										<path
											id="Path_344"
											data-name="Path 344"
											className="cls-2f"
											d="M23.964,4.064a5.494,5.494,0,0,0-7.3,0L2.615,16.552a1.831,1.831,0,1,0,2.433,2.738l.615-.546V29.029a33.68,33.68,0,0,0,.15,4.143,5.441,5.441,0,0,0,1.459,3.285,5.442,5.442,0,0,0,3.285,1.459,33.711,33.711,0,0,0,4.143.15H25.929a33.712,33.712,0,0,0,4.143-.15,5,5,0,0,0,4.743-4.743,33.673,33.673,0,0,0,.15-4.142V18.743l.615.546a1.831,1.831,0,1,0,2.434-2.738Zm-3.65,23.014a1.831,1.831,0,0,0-1.831,1.831v3.663a1.831,1.831,0,1,1-3.663,0V28.909a5.494,5.494,0,1,1,10.988,0v3.663a1.831,1.831,0,1,1-3.663,0V28.909A1.831,1.831,0,0,0,20.314,27.077Z"
											transform="translate(-2 -2.676)"
										></path>
										<text
											id="_102"
											data-name="102"
											className="cls-3f"
											transform="translate(-18 28)"
											dominant-baseline="middle"
											style={{ zIndex: "-100" }}
											text-anchor="middle"
										>
											<tspan
												y="0"
												x="98%"
												// text-anchor="end"
												// dx="12.886055946350098"
											>
												{roomNumber}
											</tspan>
										</text>
									</g>
								</g>
							</svg>
						</Link>
					</div>
					{businessType != "Hospital" && (
						<div>
							<Link
								to={`/${room_uuid}/SearchPage`}
								className={
									path === "SearchPage"
										? "active-footer"
										: null
								}
								onClick={() => {
									ReactGA.event({
										category: "Navbar Link",
										action: "Click",
										value: "search page",
									});
								}}
							>
								<img
									src={iconsearch}
									alt="Search"
									className="search-img"
									width={"23px"}
									height={"auto"}
								/>
							</Link>
						</div>
					)}

					{isWhatsapp && (
						<div>
							<a
								href={`https://wa.me/${whatsappNumber}`}
								target="_blank"
								className={
									path === "/Whatsappchat"
										? "active-footer"
										: null
								}
								onClick={() => {
									ReactGA.event({
										category: "Navbar Link",
										action: "Click",
										value: "whatsapp",
									});
								}}
							>
								<img
									src={iconwhatsapp}
									alt="Whatsapp"
									className="whatsapp-img"
									width={"23px"}
									height={"auto"}
								/>
							</a>
						</div>
					)}
				</div>
			)}
		</>
	);
}
export default Footer;
