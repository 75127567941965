import React from "react";
import { Accordion } from "react-bootstrap";
import Clientinfo from "../../Components/Clientinfo";
import { Link, useLocation } from "react-router-dom";
import { useTranslation } from "react-i18next";
import Gif from "../../assets/images/add-to-cart.gif";
import Title from "../../new-components/title/Title";
function LaundryPayment() {
  const location = useLocation();
  const rawPath = location.pathname;
  const room_uuid = rawPath.split("/")[1];

  const metadata = location.state?.metadata;
  console.log("metadata", metadata);
  const currency = localStorage.getItem("currency");

  const { roomNumber, orderNumber, total, laundry_order } = metadata;

  function groupByService(orders) {
    const grouped = {};

    for (const order of orders) {
      const { laundry_service_name, laundry_item_name, price, quantity } =
        order;

      if (!grouped[laundry_service_name]) {
        grouped[laundry_service_name] = {
          service: laundry_service_name,
          items: [],
        };
        grouped[laundry_service_name].totalPrice = 0;
        grouped[laundry_service_name].totalQuantity = 0;
      }

      grouped[laundry_service_name].items.push({
        laundry_item_name,
        price,
        quantity,
      });
      grouped[laundry_service_name].totalPrice += parseFloat(price);
      grouped[laundry_service_name].totalQuantity += quantity;
    }

    return Object.values(grouped);
  }
  const { t } = useTranslation();

  // Get the grouped orders
  const groupedOrders = groupByService(laundry_order?.order_services);

  return (
    <div className="food pb-2">
      <Title title={"Order Receipt"} />
      <main className=" laundry-order-container ">
        <div className="img-pnl">
          <img src={Gif} alt="Place Order" />
        </div>
        <h2 className="order-placed">{t("Your Order is placed")} </h2>
        <Clientinfo
          roomNumber={roomNumber}
          orderNumber={orderNumber}
          orderedAt={laundry_order?.pick_up_datetime}
        />

        <div className="full-heading grey-bg flex">
          <h2 className="blck  co-header">{t("Order Details")} </h2>
        </div>
        {/* <div className="padd-div order-accordian">
          <Accordion className="v2" orders>
            <Accordion.Item eventKey="0">
              <Accordion.Header>
                <p>
                  <b>× 2</b> Cleaning Clothes
                </p>
                <p>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="12.297"
                    height="7.563"
                    viewBox="0 0 12.297 7.563"
                  >
                    <g
                      id="Group_38233"
                      data-name="Group 38233"
                      transform="translate(3338.363 10297.648) rotate(-90)"
                    >
                      <line
                        id="Line_40"
                        data-name="Line 40"
                        x1="4.734"
                        y2="4.734"
                        transform="translate(10291.5 -3336.949)"
                        fill="none"
                        stroke="#006390"
                        stroke-linecap="round"
                        stroke-width="2"
                      ></line>
                      <line
                        id="Line_41"
                        data-name="Line 41"
                        x1="4.734"
                        y1="4.734"
                        transform="translate(10291.5 -3332.215)"
                        fill="none"
                        stroke="#006390"
                        stroke-linecap="round"
                        stroke-width="2"
                      ></line>
                    </g>
                  </svg>
                </p>
              </Accordion.Header>
              <Accordion.Body>
                <div className="d-flex">
                  <div className="img-pnl">
                    <img src={Laundry} alt="Laundry" />
                  </div>
                  <div className="text-pnl">
                    <div className="full-div">
                      <p>
                        - 2 Sheets
                        <br></br>- Blanket
                        <br></br>- 2 shirts
                      </p>
                    </div>
                  </div>
                </div>
              </Accordion.Body>
            </Accordion.Item>
          </Accordion>
        </div> */}
        {groupedOrders &&
          groupedOrders.map((order) => {
            return (
              <div className="padd-div order-accordian">
                <Accordion className="v2" orders>
                  <Accordion.Item eventKey="0">
                    <Accordion.Header>
                      <p>
                        <b>× {order.totalQuantity}</b> {order.service}
                      </p>
                      <p>
                        {currency} {parseFloat(order.totalPrice)}{" "}
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="12.297"
                          height="7.563"
                          viewBox="0 0 12.297 7.563"
                        >
                          <g
                            id="Group_38233"
                            data-name="Group 38233"
                            transform="translate(3338.363 10297.648) rotate(-90)"
                          >
                            <line
                              id="Line_40"
                              data-name="Line 40"
                              x1="4.734"
                              y2="4.734"
                              transform="translate(10291.5 -3336.949)"
                              fill="none"
                              stroke="#006390"
                              stroke-linecap="round"
                              stroke-width="2"
                            ></line>
                            <line
                              id="Line_41"
                              data-name="Line 41"
                              x1="4.734"
                              y1="4.734"
                              transform="translate(10291.5 -3332.215)"
                              fill="none"
                              stroke="#006390"
                              stroke-linecap="round"
                              stroke-width="2"
                            ></line>
                          </g>
                        </svg>
                      </p>
                    </Accordion.Header>
                    <Accordion.Body>
                      {order.items &&
                        order.items.map((item) => {
                          return (
                            <div className="d-flex mb-2">
                              <div className="img-pnl">
                                <img src={item?.laundry_item_image} />
                              </div>
                              <div className="text-pnl">
                                <div
                                  className="full-div d-flex"
                                  style={{
                                    justifyContent: "space-between",
                                    marginTop: "10px",
                                  }}
                                >
                                  <p>
                                    - {item.quantity} {item.laundry_item_name}
                                  </p>
                                  <p>{item?.price}</p>
                                </div>
                              </div>
                            </div>
                          );
                        })}
                    </Accordion.Body>
                  </Accordion.Item>
                </Accordion>
              </div>
            );
          })}
        <div className="padd-div  order-accordian ">
          <Accordion className="v2" defaultActiveKey="0" ordertotal>
            <Accordion.Item eventKey="0">
              <Accordion.Header>
                <p>{t("Order Total")} </p>
                <p>
                  {currency} {total ? total : ""}{" "}
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="12.297"
                    height="7.563"
                    viewBox="0 0 12.297 7.563"
                  >
                    <g
                      id="Group_38233"
                      data-name="Group 38233"
                      transform="translate(3338.363 10297.648) rotate(-90)"
                    >
                      <line
                        id="Line_40"
                        data-name="Line 40"
                        x1="4.734"
                        y2="4.734"
                        transform="translate(10291.5 -3336.949)"
                        fill="none"
                        stroke="#006390"
                        stroke-linecap="round"
                        stroke-width="2"
                      ></line>
                      <line
                        id="Line_41"
                        data-name="Line 41"
                        x1="4.734"
                        y1="4.734"
                        transform="translate(10291.5 -3332.215)"
                        fill="none"
                        stroke="#006390"
                        stroke-linecap="round"
                        stroke-width="2"
                      ></line>
                    </g>
                  </svg>
                </p>
              </Accordion.Header>
              <Accordion.Body className="total-body">
                <div className="flex-div">
                  <p>{t("Sub Total")} </p>
                  <p>
                    {currency} {total}
                  </p>
                </div>
                <div className="flex-div">
                  <p>{t("Discount")} </p>
                  <p>{currency} 0.00</p>
                </div>
                <div className="flex-div">
                  <h6>{t("Order Total")} </h6>
                  <h6>
                    {currency} {total}
                  </h6>
                </div>
              </Accordion.Body>
            </Accordion.Item>
          </Accordion>
        </div>
        {/* <div className="full-heading grey-bg flex">
          <h2 className="blck  co-header">{t("Payment Options")} </h2>
          <h2 className="grey">
            <img src={Payment1} alt="cash" />
            {t("cash on delivery")}
          </h2>
        </div> */}
        <div className="full-div padd-div text-center px-4">
          <Link className="reg-btn large " to={`/${room_uuid}/qr-code`}>
            {t("Back To Home")}
          </Link>
        </div>
      </main>
    </div>
  );
}
export default LaundryPayment;
