import React, { useContext, useEffect } from "react";
import HeaderInner from "../../Components/HeaderInner";
import Textheader from "../../Components/Textheader";
import Clientinfo from "../../Components/Clientinfo";
import Post1 from "../../assets/images/Spa/Post-3.png";
import order from "../../assets/images/order.png";
import { toast } from "react-toastify";
import { Link, useLocation } from "react-router-dom";
import { useTranslation } from "react-i18next";
import Gif from "../../assets/images/add-to-cart.gif";
import Title from "../../new-components/title/Title";
import NewFooter from "../../NewFooter";
import "../../new-pages/hotel-services/HotelServices.scss";
import { ThemeContext } from "../../context/ThemeContext";
function HKOrderPlaced() {
  const location = useLocation();
  const rawPath = location.pathname;
  const room_uuid = rawPath.split("/")[1];
  const { t, i18n } = useTranslation();
  // const theme = location.search;
  // const new_design = localStorage.getItem("new_design");
  // const theme = new_design == "true";
  const { theme } = useContext(ThemeContext);
  useEffect(() => {
    toast.success(t("Service is Booked", {
      className: theme ? 'custom-toast' : '',
      bodyClassName: theme ? 'custom-toast' : '',
      progressClassName: theme ? 'custom-toast' : '',
    }));
  }, []);
  return (
    <div className={theme ? "food" : ""}>
      {theme ? <Title title={"Order Details"} /> : <HeaderInner />}
      <main className="bg-white back-white pad-top-0 hk-order-container">
        <div className="img-pnl">
          <img src={Gif} alt="Place Order" />
        </div>
        <div className="text-pnl">
          <h2>{t("Thank You!")} </h2>
          <p className="confirmed">{t("Your booking is confirmed")} </p>
        </div>
        <div className="full-div padd-div text-center">
          <Link className="reg-btn big" to={`/${room_uuid}/qr-code`}>
            {t("Back to Home")}
          </Link>
        </div>
      </main>
      {theme && <NewFooter />}
    </div>
  );
}
export default HKOrderPlaced;
