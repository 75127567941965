import React, { useContext, useEffect, useState } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { Button, Form, Row, Col, Modal } from "react-bootstrap";
import Post from "../assets/images/Laundry/Laundry.png";
import { Dropdown } from "primereact/dropdown";
import useDropdownMenu from "react-accessible-dropdown-menu-hook";
import axios from "../axios";
import { useCartStore } from "../store/useStore";
import { object } from "yup";
import { useTranslation } from "react-i18next";
import LaundryCarosle from "./LaundryCarosle";
import ReactGA from "react-ga4";
import { ThemeContext } from "../context/ThemeContext";

function ViewLaundryCartModal({
  laundryId,
  show,
  handleClose,
  laundry_uuid,
  itemChange,
  is_express,
  service_fee,
  vat,
  discount_percentage,
  min_amount,
  data,
  images,
  use_banners_in_popup,
}) {
  const [selectedItem, setSelectedItem] = useState(null);
  const [selectedCategory, setSelectedCategory] = useState(null);
  const [selectedService, setSelectedService] = useState(null);
  const [count, setCount] = useState(1);
  const { t, i18n } = useTranslation();
  const location = useLocation();
  const rawPath = location.pathname;
  const room_uuid = rawPath.split("/")[1];
  const [laundaryItems, setLaundaryItems] = useState([]);
  const [laundaryCategories, setLaundaryCategories] = useState([]);
  const [isOpen, setIsOpen] = useState(false);
  const [laundaryServices, setLaundaryServices] = useState([]);
  const [currentService, setCurrentService] = useState();
  const [price, setPrice] = useState();
  // const new_design = localStorage.getItem("new_design");
  // const theme = new_design == "true";

  const { theme } = useContext(ThemeContext);

  console.log("theme", theme);
  const { laundryCart, setLaundryCart } = useCartStore((state) => ({
    laundryCart: state.laundryCart,
    setLaundryCart: state.setLaundryCart,
  }));

  // const { buttonProps, itemProps, isOpen } = useDropdownMenu(2);
  // console.log(
  //   "is_express",
  //   (Number(currentService?.price) *
  //     Number(currentService?.express_percentage)) /
  //     100 +
  //     Number(currentService?.price)
  // );
  // console.log("is_express");
  useEffect(() => {
    if (itemChange) {
      setSelectedCategory(itemChange?.category);
      setSelectedItem(itemChange);

      setSelectedService(itemChange.services_name);
      setLaundaryServices(itemChange?.services);
      setPrice(itemChange?.price);
      setCount(itemChange.quantity);
      setCurrentService(itemChange?.services_item);
    } else {
      setSelectedCategory(null);
      setSelectedItem(null);
      setSelectedService(null);
      setLaundaryServices(null);
      setCurrentService(null);
      setPrice(null);
      setCount(1);
    }
  }, [itemChange]);

  const navigate = useNavigate();
  const handleIncrease = () => {
    setCount((prev) => {
      if (prev <= 1) {
        return 1;
      }
      return prev - 1;
    });
    ReactGA.event({
      category: "Decrease count in laundry page ",
      action: "Click",
      value: `count: ${count} `,
    });
  };
  const handleDecrease = () => {
    setCount((prev) => prev + 1);
    ReactGA.event({
      category: "Increase count in laundry page ",
      action: "Click",
      value: `count: ${count} `,
    });
  };

  const SetCartItem = (item) => {
    const tempCart = [...JSON.parse(JSON.stringify(laundryCart)), item];
    setLaundryCart(tempCart);
  };
  const handleSelectItem = (item) => {
    if (item.id === selectedItem?.id) {
      setSelectedItem(null);
    } else {
      setSelectedItem(item);
      setSelectedService(null);
      setCurrentService(null);
    }
  };

  const lang = localStorage.getItem("lang");
  useEffect(() => {
    const getData = async () => {
      axios
        .get(`/${room_uuid}/laundry/${laundry_uuid}`, {
          headers: {
            lang: lang,
          },
        })
        .then(async (res) => {
          const data = res.data.data.laundry;
          setLaundaryItems(data.items);
          setLaundaryCategories(data.categories);
          // setLaundaryServices(data.services);
          // setLaundaryItems(data);
        });
    };
    getData();
  }, [lang]);
  useEffect(() => {
    const getCategories = async () => {
      if (laundaryCategories.length > 0) {
        const tempCategory = laundaryCategories.find(
          (cat) => cat.name === selectedCategory
        );
        axios
          .get(`/${room_uuid}/laundry/categure_items/${tempCategory.id}`, {
            headers: {
              lang: lang,
            },
          })
          .then((res) => {
            const data = res.data.data;
            setLaundaryItems(data);
            // setLaundaryServices(data.services);
          });
      }
    };
    getCategories();
  }, [selectedCategory, lang]);

  const removeItem = async (item) => {
    const tempItems = await laundryCart.filter((a) => item.id !== a.id);

    await setLaundryCart(tempItems);
  };

  useEffect(() => {
    setLaundaryServices(selectedItem?.services);
  }, [selectedItem, lang]);
  const handleSubmit = async () => {
    ReactGA.event({
      category: "Submit after click on button 'Add'  in laundry page ",
      action: "Click",
      value: ` `,
    });
    if (selectedItem && selectedService) {
      if (rawPath.split("/")[2] !== "LaundryOrderPayment") {
        navigate(
          theme
            ? `/${room_uuid}/hotel-services/laundry/order/${laundry_uuid}/${laundryId}/v/${vat}/fee/${service_fee}/min/${min_amount}`
            : `/${room_uuid}/LaundryOrderPayment/${laundry_uuid}/${laundryId}/v/${vat}/fee/${service_fee}/min/${min_amount}`
        );
      }
      SetCartItem({
        ...selectedItem,
        quantity: count,
        category: selectedCategory,
        services_name: selectedService,
        service_id: currentService.id,
        item_id: selectedItem.id,
        price: currentService?.price,
        price_express:
          (Number(currentService?.price) *
            Number(currentService?.express_percentage)) /
          100 +
          Number(currentService?.price),
        services_item: selectedItem?.services.filter(
          (a) => a.id == currentService.id
        )[0],
      });
      setSelectedItem(null);
      setCurrentService(null);
      // setLaundaryCategories(null);

      handleClose();
    }
  };

  const handelEdit = async () => {
    // if (selectedItem && selectedService) {
    //   if (rawPath.split("/")[2] !== "LaundryOrderPayment") {
    //     navigate(
    //       `/${room_uuid}/LaundryOrderPayment/${laundry_uuid}/${laundryId}`
    //     );
    //   }
    //   SetCartItem({
    //     ...selectedItem,
    //     quantity: count,
    //     category: selectedCategory,
    //     services_name: selectedService,
    //     service_id: currentService.id,
    //     item_id: selectedItem.id,
    //     price: currentService?.price,
    //     services_item: selectedItem?.services.filter(
    //       (a) => a.id == currentService.id
    //     )[0],
    //   });
    //   setSelectedItem(null);
    //   setCurrentService(null);
    //   // setLaundaryCategories(null);

    //   handleClose();
    // }

    const update = laundryCart.filter((a) => {
      if (a.id == itemChange.id) {
        return [
          (a.name = selectedItem.name),
          (a.id = selectedItem.id),
          (a.image = selectedItem.image),
          (a.services = selectedItem.services),
          (a.quantity = count),
          (a.category = selectedCategory),
          (a.services_name = selectedService),
          (a.service_id = currentService.id),
          (a.item_id = selectedItem.id),
          (a.price = currentService?.price),
          (a.price_express =
            (Number(currentService?.price) *
              Number(currentService?.express_percentage)) /
            100 +
            Number(currentService?.price)),
          (a.services_item = selectedItem?.services.filter(
            (a) => a.id == currentService.id
          )[0]),
        ];
      }
      return a;
    });
    handleClose();
  };
  const currency = localStorage.getItem("currency");

  // const selectedServiceItem = () => {
  //   if (itemChange) {
  //     itemChange.services.map((ser) => {
  //       if (ser.id == itemChange.service_id)
  //         // console.log("sjskskkssk", ser.name);
  //         return ser.name;
  //     });
  //   }
  // };
  // selectedServiceItem();

  console.log("discount_percentage", discount_percentage);
  return (
    <>
      <Modal
        show={show}
        onHide={handleClose}
        className={`${theme ? "food-modal" : ""
          } laundary-modal custom-width b-r ${lang == "ar" ? "ar_lang" : ""}`}
      >
        <Modal.Body>
          <Button className="close-btn" onClick={handleClose}>
            <div className="close-btn-wrap"></div>
            <div className="icon">
              {/* <RxCross2 size={".45em"} color="black" /> */}
              <svg
                xmlns="http://www.w3.org/2000/svg"
                height=".45em"
                viewBox="0 0 384 512"
              >
                <path d="M342.6 150.6c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0L192 210.7 86.6 105.4c-12.5-12.5-32.8-12.5-45.3 0s-12.5 32.8 0 45.3L146.7 256 41.4 361.4c-12.5 12.5-12.5 32.8 0 45.3s32.8 12.5 45.3 0L192 301.3 297.4 406.6c12.5 12.5 32.8 12.5 45.3 0s12.5-32.8 0-45.3L237.3 256 342.6 150.6z" />
              </svg>
            </div>
          </Button>
          <div className="laundray-img-container">
            {use_banners_in_popup ? (
              <LaundryCarosle headerImages={images} />
            ) : (
              <LaundryCarosle headerImages={images} />
            )}
          </div>
          <div className="clear"></div>
          <div className="full-div padd-div add-item">
            <i className="fa fa-plus-square" style={{ color: "#00638E" }}></i>
            <p>{t("Add Item")}</p>
          </div>
          <div className="full-div padd-div">
            <div className="flex justify-content-center drop-container">
              <Dropdown
                value={selectedCategory ? selectedCategory : null}
                onChange={(e) => {
                  setSelectedCategory(e.value);
                  ReactGA.event({
                    category: "Select Category in laundry page ",
                    action: "Click",
                    value: `category_name: ${e.value}`,
                  });
                  // console.log(`category_name: ${e.value}`);
                  setSelectedItem(null);
                  setSelectedService(null);
                  setCurrentService(null);
                }}
                panelClassName={`panel-drop tar ${theme ? "dark_theme" : ""}`}
                options={laundaryCategories ? laundaryCategories : []}
                optionLabel="name"
                optionValue="name"
                placeholder={t(`Select a Category`)}
                className="w-full md:w-14rem tar"
              />
            </div>
            <div className="flex justify-content-center drop-container mt-3">
              <div
                className={
                  isOpen
                    ? "f-dropdown11 selectDropdown open"
                    : "f-dropdown11 selectDropdown"
                }
                onClick={() => (isOpen ? setIsOpen(false) : setIsOpen(true))}
              >
                <select
                  className="f-control11 f-dropdown11"
                  placeholder="Select Item"
                  style={{ color: "#000000!important;" }}
                  name="toproductID"
                  id="toproducts"
                ></select>
                <ul style={isOpen ? {} : { display: "none" }}>
                  {laundaryItems && selectedCategory
                    ? laundaryItems.map((item) => {
                      return (
                        <li
                          onClick={() => {
                            handleSelectItem(item);
                            ReactGA.event({
                              category: "Select item in laundry page ",
                              action: "Click",
                              value: `item name: ${item.name} `,
                            });
                          }}
                        >
                          <a data-val="431" data-cat="26">
                            <img src={item.image} />
                            <span>{item.name}</span>
                          </a>
                        </li>
                      );
                    })
                    : null}
                </ul>
                <span>
                  {selectedItem ? (
                    <>
                      <img src={selectedItem.image} />
                      <span>{selectedItem.name}</span>
                    </>
                  ) : (
                    <span>{t(`Select Item`)}</span>
                  )}
                </span>
              </div>
            </div>
            <div className="flex justify-content-center drop-container mt-3">
              <Dropdown
                value={selectedService ? selectedService : null}
                onChange={(e) => {
                  setSelectedService(e.value);
                  ReactGA.event({
                    category: "Select service in laundry page ",
                    action: "Click",
                    value: `service name: ${e.value} `,
                  });

                  const tempService = selectedItem?.services.filter(
                    (a) => a.name === e.value
                  )[0];
                  setCurrentService(tempService);
                }}
                panelClassName={`panel-drop  ${theme ? "dark_theme" : ""}`}
                options={laundaryServices ? laundaryServices : []}
                optionLabel="name"
                optionValue="name"
                placeholder={t(`Select Serivce`)}
                className="w-full md:w-14rem"
              />
            </div>
            {/* ********************  */}

            {/*  */}
            <div className="d-flex justify-content-between py-3 align-items-center flex-wrap  mt-2">
              <Form.Group
                className="mb-3 d-flex align-items-center"
                controlId="formBasicEmail"
              >
                <Form.Label className="count">{t(`Count`)}</Form.Label>
                <div className="count-order-amount mx-2">
                  <Button className="small" onClick={handleIncrease}>
                    <i className="fa fa-minus"></i>
                  </Button>
                  <p>{count}</p>
                  <Button className=" small" onClick={handleDecrease}>
                    <i className="fa fa-plus"></i>
                  </Button>
                </div>
              </Form.Group>
              <div className="d-flex">
                <Form.Label className="mr-2 f-16">{t(`Price`)}</Form.Label>

                {discount_percentage && (
                  <p className="f-16 discount_percentage">
                    {is_express
                      ? currentService
                        ? `${currency} ${(Number(currentService?.price) *
                          Number(currentService?.express_percentage)) /
                        100 +
                        Number(currentService?.price) * count -
                        (Number(currentService?.price) *
                          Number(currentService?.express_percentage)) /
                        100 +
                        Number(currentService?.price) *
                        count *
                        Number(discount_percentage / 100)
                        }`
                        : price
                          ? `${currency} ${Number(price) * count -
                          Number(price) *
                          count *
                          Number(discount_percentage / 100)
                          }`
                          : ""
                      : currentService
                        ? `${currency} ${currentService.price * count -
                        currentService.price *
                        count *
                        Number(discount_percentage / 100)
                        }`
                        : price
                          ? `${currency} ${Number(price) * count -
                          Number(price) *
                          count *
                          Number(discount_percentage / 100)
                          }`
                          : ""}
                  </p>
                )}
                <p
                  className={`f-16 ${discount_percentage
                    ? "laundry_price"
                    : "discount_percentage"
                    }`}
                >
                  {is_express
                    ? currentService
                      ? `${currency} ${(Number(currentService?.price) *
                        Number(currentService?.express_percentage)) /
                      100 +
                      Number(currentService?.price) * count
                      }`
                      : price
                        ? `${currency} ${Number(price) * count}`
                        : ""
                    : currentService
                      ? `${currency} ${currentService.price * count}`
                      : price
                        ? `${currency} ${Number(price) * count}`
                        : ""}
                </p>
              </div>
            </div>
            <Row className="mt-4">
              <Col lg="6" md="6" xs="6" className="p-0  pl-2">
                {itemChange ? (
                  <Button
                    className="reg-btn big laundary-btn"
                    onClick={handelEdit}
                  >
                    {t(`Edit`)}
                  </Button>
                ) : (
                  <Button
                    className="reg-btn big laundary-btn"
                    onClick={handleSubmit}
                  >
                    {t(`Add`)}
                  </Button>
                )}
              </Col>
              <Col lg="6" md="6" xs="6" className="p-0 px-2">
                <Button
                  className="reg-btn big laundary-btn cancel"
                  onClick={handleClose}
                >
                  {t(`Cancel`)}
                </Button>
              </Col>
            </Row>
          </div>
          <div className="spacer-10"></div>
        </Modal.Body>
      </Modal>
    </>
  );
}
export default ViewLaundryCartModal;
