import React, { useEffect, useState } from "react";

import { Button, Col, Form, Modal, Row } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import PhoneInput from "react-phone-input-2";
import axios from "../../axios";
import { useNavigate } from "react-router-dom";
import { phoneFormat } from "../../constants/api";
import PhoneInputComponent from "../../Components/PhoneInputComponent";

const ModalGeneralInformationInfo = ({
  show,
  setShow,
  category,
  room_uuid,
  text,
  theme,
  desMod
}) => {
  const [loading, setLoading] = useState(false);
  const [isDisabled, setIsDisabled] = useState(false);
  const [phone, setPhone] = useState("");
  const [email, setEmail] = useState("");
  const [name, setName] = useState("");


  const { t } = useTranslation();

  const navigate = useNavigate();


  const handleClose = () => {
    setShow(false);
    setPhone("");
  };

  const dataForm = {
    phone: phone?.phone,
    category: category,
    name: name,
    email: email,
  };

  const [phoneErr, setPhoneErr] = useState("")

  const checkInputs = () => {

    if (!phone) {
      setPhoneErr("The phone field is required.");
    } else {
      setPhoneErr("");
    }

  };
  const handelBook = () => {
    checkInputs();
    if (
      phone
    ) {
      setIsDisabled(true);
      axios
        .post(`/${room_uuid}/hospital-my-feedback/book-service`, dataForm)
        .then((res) => {
          console.log("ressssss", res);
          if (res.data.status) {
            navigate(`/${room_uuid}/HKOrderPlaced`, {

            });
            setPhone("");
          }
          setIsDisabled(false);
        })
        .catch((err) => {
          setIsDisabled(false);
          console.log(err);
        });
    };
  };

  console.log("phoen*88888", phone);


  return (
    <Modal
      show={show}
      onHide={handleClose}
      className={`custom-width pt-4 ${theme ? "food-modal" : ""}`}
    >
      <Modal.Body>
        <button
          type="button"
          className="button close-pop"
          onClick={handleClose}
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="24.054"
            height="24.625"
            viewBox="0 0 24.054 24.625"
          >
            <defs></defs>
            <path
              id="Icon_ionic-ios-close-circle"
              data-name="Icon ionic-ios-close-circle"
              className="cls-50"
              d="M15.4,3.375A12.17,12.17,0,0,0,3.375,15.687,12.17,12.17,0,0,0,15.4,28,12.17,12.17,0,0,0,27.429,15.687,12.17,12.17,0,0,0,15.4,3.375Zm3.047,16.77L15.4,17.025l-3.047,3.12a.913.913,0,0,1-1.307,0,.959.959,0,0,1,0-1.338l3.047-3.12-3.047-3.12a.959.959,0,0,1,0-1.338.913.913,0,0,1,1.307,0L15.4,14.35l3.047-3.12a.913.913,0,0,1,1.307,0,.966.966,0,0,1,0,1.338l-3.047,3.12,3.047,3.12a.966.966,0,0,1,0,1.338A.9.9,0,0,1,18.449,20.145Z"
              transform="translate(-3.375 -3.375)"
            ></path>
          </svg>
        </button>
        <div className="body-container maintenance ">
          {/* <div className="img-pnl">
            <img className="mod_img" src={imageMod} />
          </div> */}
          <h4>{text}</h4>
          {desMod &&
            <div className="div-des"
              dangerouslySetInnerHTML={{
                __html: desMod,
              }}
            />
          }
          <div className="content-pnl">
            <div className="input-pnl">
              <Row>
                <Col xs="12">
                  <Form.Group
                    className="mb-3 dark-placeholder i-f-small"
                    controlId="formBasicname"
                  >
                    <Form.Control
                      type="text"
                      placeholder={t(`Your Name`)}
                      name="name"
                      value={name}
                      onChange={(e) => setName(e.target.value)}
                    />
                  </Form.Group>
                  {/* {errName != "" && (
                    <span className="err_msg">{t(`${errName}`)}</span>
                  )} */}
                </Col>
                <Col xs="12">
                  <Form.Group
                    className="mb-3 dark-placeholder i-f-small"
                    controlId="formBasicname"
                  >
                    <Form.Control
                      type="email"
                      placeholder={t(`Your Email`)}
                      name="email"
                      value={email}
                      onChange={(e) => setEmail(e.target.value)}
                    />
                  </Form.Group>
                  {/* {errName != "" && (
                    <span className="err_msg">{t(`${errName}`)}</span>
                  )} */}
                </Col>
                <Col xs="12">
                  <Form.Group className="mb-3 dark-placeholder i-f-small w-100">
                    <PhoneInputComponent
                      onChange={(phone) => {
                        setPhone((prev) => ({ ...prev, phone }));
                      }}
                    />
                  </Form.Group>
                  {phoneErr !== "" && (
                    <span className="err_msg">{t(`${phoneErr}`)}</span>
                  )}
                </Col>


                {/* {previewUrls.map((url, index) => (
                  <img key={index} src={url} />
                ))} */}
                {/* {Object.values(responseLinks).map((item) => {
                  return <p>{item}</p>;
                })} */}
              </Row>
            </div>
            <div className="spacer-40"></div>
            <div className="padd-div text-center mb-2 ">
              <Button
                className="reg-btn book fw-normal"
                onClick={handelBook}
                disabled={isDisabled}
              >
                {t(`Book`)}
              </Button>
            </div>
          </div>
        </div>
      </Modal.Body>
    </Modal>
  );
};

export default ModalGeneralInformationInfo;
