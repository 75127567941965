import React, { useState } from "react";
import fallbackImage from "../assets/images/DF_super.jpg";
const ImageSuperMarket = ({ src }) => {
  const [imageSrc, setImageSrc] = useState(src);
  const [imageError, setImageError] = useState(false);

  const handleImageError = () => {
    if (!imageError) {
      setImageSrc(fallbackImage);
      setImageError(true);
    }
  };
  return <img src={imageSrc} onError={handleImageError} />;
};

export default ImageSuperMarket;
