import React, { useEffect } from "react";
import Title from "../../new-components/title/Title";
import { Link, useNavigate, useParams } from "react-router-dom";
import Overllay from "../../assets/new_imgs/Rectangle 1072.png";
import NewFooter from "../../NewFooter";
import "./FoodAndBeverages.scss";
import { ShowRestaurantBooking } from "../../API/RestaurantBookingAPI";
import NewLoader from "../../new-components/title/Loader/NewLoader";
import BannerHome from "../../new-components/banner-home/BannerHome";

const NewRestaurantBooking = () => {
  const params = useParams();
  const lang = localStorage.getItem("lang");
  const navigate = useNavigate();
  let restaurants;
  let menu;
  let text;
  const { data, isLoading } = ShowRestaurantBooking(
    params.room_uuid,
    lang
  );
  if (!isLoading) {
    if (data.data.status === false) {
      navigate(`/${params.room_uuid}/qr-scan`);
    } else {
      menu = data.data.data;
      restaurants = menu?.restaurants;
      text = menu?.center_header;
    }
  }
  if (!isLoading) {
  }
  useEffect(() => {
    window.scrollTo({
      top: 0,
      left: 0,
    });
  }, []);

  return (
    <div className={`food pb-5 ${lang === "AR" && "arabic"}`}>
      {isLoading ? (
        <NewLoader />
      ) : (
        <>
          <Title title={text} />

          <div className="box_food">
            {restaurants?.map((item, idx) => {
              return (

                <div style={{ position: "relative", widows: "96%", margin: "auto", marginBottom: "40px", }} onClick={() => navigate(`/${params.room_uuid}/food-and-beverages/restaurant-booking/${item.uuid}`)}>
                  <BannerHome
                    headerImages={item.real_video
                      ? [{ image: item.real_video }, ...item.images]
                      : item.images.length == 0
                        ? [{ image: item.image }]
                        : item.images
                    }
                    isVideo={item.real_video ? true : false}
                    imageOverllay={true}
                    isBookingList={true}
                  />
                  {/* <img src={item?.image} alt="" className="booking_img" /> */}
                  {/* <img src={Overllay} alt="" className="" /> */}
                  <div className="text_food" style={{ bottom: "15px", cursor: "pointer" }} >

                    <h5 className="">
                      {" "}
                      {lang === "EN" ? item.display_name : item.name}
                    </h5>
                    <p>
                      {item?.cuisines?.map((cuisine, idx) => {
                        if (idx + 1 < item?.cuisines.length) {
                          return `${cuisine.name}  |  `;
                        } else {
                          return `${cuisine.name}  `;
                        }
                      })}
                    </p>

                  </div>

                </div>

              );
            })}
          </div>
          <NewFooter />
        </>
      )}
    </div>
  );
};

export default NewRestaurantBooking;
