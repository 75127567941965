import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { Button, Col, Form, Row } from "react-bootstrap";
import Car11 from "../assets/images/Car-Rental/cardisplay.png";
import PhoneInput from "react-phone-input-2";
import SpaLoader from "./Loaders/SpaLoader";
import axios from "../axios";
import { useTranslation } from "react-i18next";
import { phoneFormat } from "../constants/api";
import PhoneInputComponent from "./PhoneInputComponent";

function CarBookModal({ car, room_uuid }) {
	const [count, setCount] = useState(1);

	const [dateInput, setDateInput] = useState(
		new Date().toISOString().split("T")[0]
	);
	const [timeInput, setTimeInput] = useState(dateInput);
	const [phone, setPhone] = useState("");
	const [name, setName] = useState("");
	const [email, setEmail] = useState("");
	const [checkedItems, setCheckedItems] = useState([]);

	const [activeColor, setActiveColor] = useState(car?.colors[0]?.id);
	const [priceRate, setPriceate] = useState(null);
	const [errMsg, setErrMsg] = useState({
		name: "",
		phone: "",
		startDate: "",
		endtDate: "",
	});
	const [loading, setLoading] = useState(false);
	const navigate = useNavigate();

	// console.log("car", activeColor);
	const data = {
		car_id: car.id,
		car_color: activeColor,
		payment_frequency: car.payment_frequency,
		name: name,
		email: email,
		phone: phone,
		start_date: dateInput,
		end_date: timeInput,
		ancilaries: checkedItems,
	};

	const dataRate = {
		car_id: car.id,
		start_date: dateInput,
		end_date: timeInput,
		ancilaries: checkedItems,
	};

	const handleDecrease = () => {
		setCount((prev) => {
			if (prev <= 0) {
				return 0;
			}
			return parseInt(prev) - 1;
		});
	};
	const handleIncrease = () => {
		setCount((prev) => parseInt(prev) + 1);
	};
	const handleTime = (e) => {
		setTimeInput(e.target.value);
		// if (dateInput) {
		//   rateFunction(e);
		// }
	};
	const handleDate = (e) => {
		setDateInput(e.target.value);
		setTimeInput(e.target.value);

		// if (timeInput) {
		//   rateFunction(e);
		// }
	};
	const [enableClass, setEnableClass] = useState(true);
	const { t, i18n } = useTranslation();
	const lang = localStorage.getItem("lang");

	const [nameErr, setNameErr] = useState("");
	const [phoneErr, setPhoneErr] = useState("");
	const [emailErr, setEmailErr] = useState("");
	const [sDateErr, setSDateErr] = useState("");
	const [edateErr, setEdateErr] = useState("");
	const [checkRate, setCheckRate] = useState(false);
	const checkEmail = email.length > 0 && !/\S+@\S+\.\S+/.test(email);
	const checkInputs = () => {
		if (name == "") {
			setNameErr("The name field is required.");
		} else {
			setNameErr("");
		}
		if (phone == "") {
			setPhoneErr("The phone field is required.");
		} else {
			setPhoneErr("");
		}
		if (dateInput == "") {
			setSDateErr("Start Date is a required field");
		} else {
			setSDateErr("");
		}
		if (timeInput == "") {
			setEdateErr("End Date is a required field");
		} else {
			setEdateErr("");
		}
		if (checkEmail) {
			setEmailErr("The email must be a valid email address.");
		} else {
			setEmailErr("");
		}
	};
	// console.log("checkEmail", checkEmail);
	// console.log("!checkEmail", !checkEmail);

	// dataRate

	const checkInputRate = () => {
		if (dateInput == "") {
			setSDateErr("Start Date is a required field");
		} else {
			setSDateErr("");
		}
		if (timeInput == "") {
			setEdateErr("End Date is a required field");
		} else {
			setEdateErr("");
		}
	};

	// const rateFunction = () => {
	//   checkInputRate();

	//   if (dateInput != "" && timeInput != "") {
	//     setCheckRate(true);
	//     // e.preventDefault();

	//     setLoading(true);

	//     axios
	//       .post(`/${room_uuid}/cars/rate`, dataRate, {
	//         headers: {
	//           lang: lang,
	//         },
	//       })
	//       .then((res) => {
	//         // console.log("res?.data?.price", res?.data?.data?.price);
	//         setPriceate(res?.data?.data?.price);
	//         // console.log("res", res);
	//         setLoading(false);
	//       })
	//       .catch((err) => {
	//         setLoading(false);
	//         console.log("err", err);
	//       });
	//     // console.log("data", data);
	//   }
	// };

	const [prices, setPrices] = useState([]);

	const handleCheckboxChange = (itemId, price, e) => {
		if (checkedItems?.includes(itemId)) {
			setPrices((prevCheckedItems) =>
				prevCheckedItems.filter((item) => item !== price)
			);

			setCheckedItems((prevCheckedItems) =>
				prevCheckedItems.filter((item) => item !== itemId)
			);
		} else {
			setCheckedItems((prevCheckedItems) => [
				...prevCheckedItems,
				itemId,
			]);
			setPrices((prevCheckedItems) => [...prevCheckedItems, price]);
		}
	};

	const handelOrder = (e) => {
		checkInputs();
		if (phone != "" && dateInput != "" && timeInput != "" && !checkEmail) {
			e.preventDefault();

			setLoading(true);

			axios
				.post(`/${room_uuid}/cars/bookCar`, data, {
					headers: {
						lang: lang,
					},
				})
				.then((res) => {
					if (res.data.status) {
						setLoading(false);
						navigate(`/${room_uuid}/CarRentalOrderPlaced`, {
							state: {
								metadata: {
									res: res.data.data,
									data: {
										img: car.images[0].image,
										price: car.price,
										name: car.car_model.name,
										year: car.year,
									},
								},
							},
						});
					}
				})
				.catch((err) => {
					setLoading(false);
					console.log("err", err);
				});
			// console.log("data", data);
		}
	};
	setTimeout(() => {
		setEnableClass(false);
	}, 1000);
	const currency = localStorage.getItem("currency");
	// useEffect(() => {
	//   if (checkedItems.length > 0) {
	//     rateFunction();
	//   } else if (checkRate) {
	//     rateFunction();
	//   }
	// }, [checkedItems]);
	const [totalPrices, setTotalPrices] = useState(0);
	useEffect(() => {
		let total = 0;
		prices.map((item) => {
			total = total + Number(item);
		});

		setTotalPrices(total);
	}, [prices]);

	return (
		<>
			{enableClass ? (
				<SpaLoader />
			) : (
				<>
					<div className="modal-item">
						<div className="car-img-container  w-100">
							<div></div>
							<img src={car.images[0].image} alt="Post" />{" "}
						</div>

						<div className="txt-pnl">
							<Link to="/RestaurantOrderPayment">
								<h2>
									{car.car_model.name} {car.year}
								</h2>
							</Link>
							{/* <div className="flex-div">
                {car.price !== 0 ||
                  (priceRate != null && (
                    <h3>
                      {currency} {car.price || priceRate}
                    </h3>
                  ))}
              </div> */}
						</div>
					</div>
					{car.year !== null || car.payment_frequency !== null ? (
						<div className="full-heading flex grey-bg m-0">
							<h3>{t("Details")} </h3>
						</div>
					) : null}

					<div className="check-list-container">
						<Form>
							<ul className="check-list v3">
								{car.year && (
									<li>
										<p> {t("Year")}</p>
										<p>{car.year}</p>
									</li>
								)}
								{car.payment_frequency && (
									<li>
										<p>{t("Payment Frequency ")} </p>
										<p>{car.payment_frequency}</p>
									</li>
								)}
								{car?.price && (
									<li>
										<p>{t("Price")} </p>

										<p>{`${
											car.price + totalPrices
										} ${currency}`}</p>
									</li>
								)}
							</ul>
						</Form>
					</div>
					{car.colors.length > 0 && (
						<>
							<div className="full-heading d-flex justify-content-start grey-bg m-0">
								<h3>{t("Color")} </h3>
								<p className="text-danger mb-0 ml-2">
									{t("Required")}{" "}
								</p>
							</div>
							<div className="padd-div">
								<ul className="flex-div color-btn-list">
									{car.colors &&
										car.colors.map((item) => {
											const { color } = item;
											return (
												<li
													id={item.id}
													className={
														activeColor === item.id
															? "color-btn active"
															: "color-btn"
													}
												>
													<Button
														style={{
															backgroundColor:
																color,
															borderColor:
																color == "White"
																	? `Gray`
																	: color,
														}}
														onClick={() =>
															setActiveColor(
																item.id
															)
														}
													></Button>
												</li>
											);
										})}
								</ul>
							</div>
						</>
					)}

					<div className="full-heading flex grey-bg m-0">
						<h3> {t("Your Information")} </h3>
					</div>
					<div className="full-heading m-0">
						<p style={{ marginBottom: 5 }}>
							{t("Enter your information and get your car")}
						</p>
					</div>

					<div className="car-input-container">
						<Row>
							<Col xs="12">
								<Form.Group
									className="mb-3 p"
									controlId="formBasicname"
								>
									<Form.Control
										type="email"
										className="pl-5"
										placeholder="Your Name"
										onChange={(e) =>
											setName(e.target.value)
										}
									/>
								</Form.Group>
								{nameErr != "" && (
									<span className="err_msg">
										{t(`${nameErr}`)}
									</span>
								)}
							</Col>
							<Col xs="12">
								<Form.Group
									className="mb-3 p"
									controlId="formBasicname"
								>
									<Form.Control
										type="text"
										className="pl-5"
										placeholder="Your Email (Optional)"
										onChange={(e) =>
											setEmail(e.target.value)
										}
									/>
								</Form.Group>
								{emailErr != "" && (
									<span className="err_msg">
										{t(`${emailErr}`)}
									</span>
								)}
							</Col>
						</Row>
						<Form.Group className="mb-3 w-100">
							<PhoneInputComponent
								value={phone}
								onChange={(phone) => setPhone(phone)}
							/>
						</Form.Group>
						{phoneErr != "" && (
							<span className="err_msg">{t(`${phoneErr}`)}</span>
						)}

						<div className="bk-date">
							<Form.Group
								className="mb-3 row align-items-center justify-content-start"
								controlId="formBasicname"
							>
								<p className="col-3 m-0 f-12 ">
									{t("Start Date")}{" "}
								</p>
								<Form.Control
									type="date"
									value={dateInput}
									onChange={handleDate}
									min={new Date().toISOString().split("T")[0]}
									placeholder="Enter Your Email"
									className="col-8 ml-2  dt-input"
								/>
							</Form.Group>
							{sDateErr != "" && (
								<span className="err_msg">
									{t(`${sDateErr}`)}
								</span>
							)}
						</div>
						<div className="bk-time mb-4">
							<Form.Group
								className="mb-3 row align-items-center justify-content-start "
								controlId="formBasicname"
							>
								<p className="col-3 m-0 f-12 no-break">
									{t("End Date")}{" "}
								</p>
								<Form.Control
									type="date"
									value={timeInput}
									min={
										dateInput != ""
											? new Date(dateInput)
													.toISOString()
													.split("T")[0]
											: new Date()
													.toISOString()
													.split("T")[0]
									}
									onChange={handleTime}
									placeholder="Enter Your Email"
									className="col-8 ml-2  dt-input"
								/>
							</Form.Group>
							{edateErr != "" && (
								<span className="err_msg">
									{t(`${edateErr}`)}
								</span>
							)}
						</div>
					</div>
					{car?.ancilaries?.length > 0 && (
						<>
							<div className="full-heading flex grey-bg m-0">
								<h3>{t("Ancilaries")} </h3>
							</div>
							<div className="salon-table ancilaries  mt-2 mb-2">
								<table>
									<tbody>
										{car?.ancilaries &&
											car?.ancilaries.map((ancilarie) => {
												return (
													<>
														<tr class="detailes">
															<td className="fw-700 text-left">
																{ancilarie?.description
																	?.charAt(0)
																	.toUpperCase() +
																	ancilarie?.description
																		.slice(
																			1
																		)
																		.toLowerCase()}
															</td>
															<td>
																<div className="car_price_td">
																	<span>{`${ancilarie?.price} ${currency}`}</span>
																	<input
																		type="checkbox"
																		class="checkbox-option"
																		id={`item${ancilarie.id}`}
																		checked={checkedItems?.includes(
																			ancilarie.id
																		)}
																		onChange={(
																			e
																		) => {
																			handleCheckboxChange(
																				ancilarie.id,
																				ancilarie?.price,
																				e
																			);
																		}}
																		data-gtm-form-interact-field-id="0"
																	/>
																</div>
															</td>
															<td hidden>698</td>
														</tr>
													</>
												);
											})}
									</tbody>
								</table>
							</div>
						</>
					)}
					<div className="check-list-container">
						<div className="full-div padd-div text-center">
							{/* <Link className="reg-btn big " to="/CarRentalOrderPlaced">
                Book Now
              </Link> */}
							{/* <div className="rate_dev">
                {car?.needs_rate ? (
                  <Button
                    className="reg-btn big"
                    onClick={rateFunction}
                    disabled={loading}
                  >
                    {t("Get Rate")}
                  </Button>
                ) : (
                  priceRate && <p>{`Rate: `}</p>
                )}
                {priceRate && (
                  <p className="pricerate">
                    {` ${priceRate}
                     ${currency}`}
                  </p>
                )}
              </div> */}
							<Button
								className="reg-btn big"
								onClick={handelOrder}
								disabled={
									loading ||
									new Date(dateInput).setHours(0, 0, 0, 0) <
										new Date().setHours(0, 0, 0, 0) ||
									new Date(timeInput).setHours(0, 0, 0, 0) <
										new Date(dateInput).setHours(0, 0, 0, 0)
								}
							>
								{t("Book Now")}
							</Button>
						</div>
					</div>
				</>
			)}
		</>
	);
}
export default CarBookModal;
