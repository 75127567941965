import React, { useEffect } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { ShowPharmacy } from "../../API/Pharmacy";
import SpaLoader from "../../Components/Loaders/SpaLoader";
import Title from "../../new-components/title/Title";
import NewFooter from "../../NewFooter";
import Overllay from "../../assets/new_imgs/Rectangle 1072.png";

function PharmacyService() {
  const location = useLocation();
  const rawPath = location.pathname;
  const room_uuid = rawPath.split("/")[1];

  const navigate = useNavigate();
  const lang = localStorage.getItem("lang");

  let parmacy = { id: "", uuid: "" };
  let pharmacies_;
  let pharmacies;
  let text;
  const { data, isLoading } = ShowPharmacy(room_uuid, lang);
  if (!isLoading) {
    if (data.data.status === false) {
      navigate(`/${room_uuid}/qr-scan`);
    } else {
      pharmacies_ = data.data.data;

      pharmacies = pharmacies_.pharmacies;
      if (pharmacies.length > 0) {
        parmacy.id = pharmacies_.pharmacies[0].id;
        parmacy.uuid = pharmacies_.pharmacies[0].uuid;
        text = pharmacies_.title;
        if (pharmacies?.length === 1) {
          navigate(
            `/${room_uuid}/pharmacy/category/${pharmacies[0].uuid}/id/${pharmacies[0].id}`,
            { replace: true, state: { theme: true } }
          );
        }
      } else {
        navigate(`/${room_uuid}/qr-scan`);
      }
    }
  }
  useEffect(() => {
    window.scrollTo({
      top: 0,
      left: 0,
    });
  }, []);

  return (
    <div className="food">
      <Title title={text} />
      <div className="box_food">
        {isLoading ? (
          <SpaLoader />
        ) : pharmacies.length > 0 ? (
          pharmacies?.map((pharmacie, idx) => {
            return (
              <div key={idx}>
                <Link
                  key={idx}
                  to={`/${room_uuid}/pharmacy/category/${pharmacie.uuid}/id/${pharmacie.id}`}
                  state={{ theme: true }}
                >
                  <img src={pharmacie?.image} alt="" className="booking_img" />
                  <img src={Overllay} alt="" className="overlay" />
                  <div className="text_food">
                    <h5 className="">{pharmacie.name}</h5>
                  </div>{" "}
                </Link>
              </div>
            );
          })
        ) : null}
      </div>
      <NewFooter />
    </div>
  );
}
export default PharmacyService;
