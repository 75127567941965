import React, { useState, useRef, useEffect, useLayoutEffect, useContext } from "react";
import HeaderInner from "../../Components/HeaderInner";
import { Button, Modal } from "react-bootstrap";
import ViewPharmacyCartModal from "../../Components/ViewPharmacyCartModal";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import useDraggableScroll from "use-draggable-scroll";
import { useCartStore } from "../../store/useStore";
import PharmacySubMenuLoader from "../../Components/Loaders/PharmacySubMenuLoader";
import ViewCart from "../../Components/ViewCart";
import { useInfiniteQuery } from "react-query";
import Search from "../../Components/Search";
import { SupermarketSubMenuAPI } from "../../API/Supermarket";
import SupermarketCartPost from "../../Components/SupermarketCartPost";
import ImageSuperMarket from "../../Components/ImageSuperMarket";
import fallbackImage from "../../assets/images/DF_super.jpg";
import SupermarketCarosle from "../../Components/SupermarketCarosle";
import ReactGA from "react-ga4";
import Title from "../../new-components/title/Title";
import BannerServices from "../../new-components/banner-home/BannerServices";
import { t } from "i18next";
import { ThemeContext } from "../../context/ThemeContext";

function SupermarketSubMenu() {
  const params = useParams();
  const [show, setShow] = useState(false);
  const navigate = useNavigate();
  const [selected, setSelected] = useState(params.id);
  // const [pharmacyItems, setPharmacyItems] = useState();
  const [total, setTotal] = useState(0);
  const [typeList, setTypeList] = useState("th");
  let ref = useRef(null);
  let subermarketItemsRef = useRef(null);
  const { onMouseDown } = useDraggableScroll(ref);
  const { cart } = useCartStore((state) => ({
    cart: state.cart,
  }));
  const location = useLocation();
  const rawPath = location.pathname;
  const room_uuid = rawPath.split("/")[1];
  // let alkl = location.search.replace("?", "");

  const handleClose = () => setShow(false);

  const checkAll = location.search.replace("?", "");
  const [all, setAll] = useState(location.search.replace("?", ""));
  const pharmacy_uuid = params.supermarket_uuid;
  const lang = localStorage.getItem("lang");
  console.log("all", all);
  // const theme = location.state?.theme;
  // const new_design = localStorage.getItem("new_design");
  // const theme = new_design == "true";
  const { theme } = useContext(ThemeContext);
  const {
    data,
    isLoading,
    hasNextPage,
    isFetchingNextPage,
    fetchNextPage,
  } = useInfiniteQuery(
    `SupermarketSubMenu-${lang}-${selected}`,
    ({ pageParam = 1 }) =>
      SupermarketSubMenuAPI({
        room_uuid,
        pharmacy_uuid,
        selected,
        pageParam,
        lang,
        all: all,
      }),
    {
      getNextPageParam: (data) => {
        const lastPage = data.data.data.items.links.last_page;
        const currentPage = data.data.data.items.links.current_page;
        return currentPage < lastPage ? currentPage + 1 : undefined;
      },
    }
  );

  let title;
  let breadcrumbs;
  let supermarket;
  let headerImages;
  if (!isLoading) {
    if (data?.pages[0].data.status == false) {
      navigate(`/${room_uuid}/qr-scan`);
    } else {
      // console.log("data", data.pages[0]);
      title = data?.pages[0]?.data?.data.title;
      breadcrumbs = data?.pages[0]?.data?.data.breadcrumbs;
      supermarket = data?.pages[0]?.data?.data.supermarket;
      headerImages = data?.pages[0]?.data?.data.banners;
    }
  }
  let loadMoreRef = useRef();

  useEffect(() => {
    if (!hasNextPage) {
      return;
    }

    const observer = new IntersectionObserver(
      (entries) =>
        entries.forEach((entry) => entry.isIntersecting && fetchNextPage()),
      {
        root: null,
        margin: "0px",
        treshold: 1.0,
      }
    );

    const el = loadMoreRef && loadMoreRef.current;

    if (!el) {
      return;
    }

    observer.observe(el);
  }, [loadMoreRef.current, hasNextPage]);

  useEffect(() => {
    let tempTotal = 0;
    cart.map((item) => {
      tempTotal += parseFloat(item.price * item.count);
    });
    setTotal(tempTotal);
  }, [cart]);

  useEffect(() => {
    window.scrollTo({
      top: 0,
      left: 0,
    });
  }, []);
  // let divRef = useRef(null);
  useEffect(() => {
    if (ref.current) {
      // console.log("ref.current", ref.current.children);

      const lists = ref.current.children;
      // console.log("ref", ref.current.children);
      // ref.current.children;
      Object.values(lists).map((list, idx) => {
        if (list.classList.contains("active")) {
          const itemWidth = list.offsetWidth;
          const scrollPosition = idx * itemWidth;

          ref.current.scrollTo({
            left: scrollPosition,
            behavior: "smooth",
          });
          //     // Your logic when the "active" class is present
          //     // console.log('The first child has the "active" class');
        }
      });
    }
    // if (h_ref.current) {
    //   heightDiv = h_ref.current.clientHeight;
    //   divRef.current.style.marginTop = `${heightDiv}px`;
    //   // divRef.current
    // }
  }, [data]);
  const devRef = useRef(null);
  useLayoutEffect(() => {
    if (devRef.current) {
      if (data?.pages[0].data?.data?.items?.data.length > 3) {
        console.log(devRef);
        const divAnimate =
          devRef.current.getBoundingClientRect().top - theme ? 0 : 70;
        const heightDiv = devRef.current.getBoundingClientRect().height;
        // console.log("heightDiv", Math.floor(heightDiv));
        // console.log("devRef", devRef);
        const onScroll = () => {
          if (divAnimate < window.scrollY) {
            console.log("ok");
            devRef.current.classList.add("fixedSearch");
            subermarketItemsRef.current.style.paddingTop = `${Math.floor(
              heightDiv
            )}px`;
          } else {
            devRef.current.classList.remove("fixedSearch");
            subermarketItemsRef.current.style.paddingTop = "0px";
          }
        };
        window.addEventListener("scroll", onScroll);
        return () => window.removeEventListener("scroll", onScroll);
      }
    }
  }, [data]);

  console.log("breadcrumbs?.all_parents?.parent?.name", breadcrumbs);

  return (
    <div className={theme ? "food" : ""}>
      {theme ? <Title title={title} /> : <HeaderInner headerName={title} />}
      <main
        className="bg-white back-white pad-top-0"
        style={{ paddingBottom: "70px" }}
      >
        {isLoading ? (
          <PharmacySubMenuLoader />
        ) : (
          <>
            {headerImages?.length > 0 &&
              (theme ? (
                <BannerServices headerImages={headerImages} />
              ) : (
                <header className="img-header m-0 mb-2">
                  <SupermarketCarosle headerImages={headerImages} />
                </header>
              ))}
            <div className="" ref={devRef}>
              <div className="search_categories_supermarket">
                <Search
                  placeholder="Search for Categories..."
                  room_uuid={room_uuid}
                  category="Supermarket"
                  className="mt-1"
                />
                <div className="icone_category">
                  <span
                    className={`${typeList === "th" && "active"}`}
                    onClick={() => {
                      ReactGA.event({
                        category: "Change the view in supermarket items page",
                        action: "Click",
                        value: `view: Grid view`,
                      });
                      setTypeList("th");
                    }}
                  >
                    <i class="fas fa-th-large"></i>
                  </span>
                  <span
                    className={`${typeList === "list" && "active"}`}
                    onClick={() => {
                      setTypeList("list");
                      ReactGA.event({
                        category: "Change the view in supermarket items page",
                        action: "Click",
                        value: `view: List view`,
                      });
                    }}
                  >
                    <i class="fas fa-list-ul"></i>
                  </span>
                </div>
              </div>

              <span className="supermarket_name">
                <span
                  onClick={() =>
                    navigate(`/${room_uuid}/supermarket/${supermarket?.uuid}`)
                  }
                >
                  {t("Categories")}
                </span>

                {breadcrumbs?.all_parents?.parent?.name && (
                  <>
                    <i class="fas fa-angle-right"></i>
                    <span
                      onClick={() => {
                        navigate(-2);
                        ReactGA.event({
                          category: "Click on breadcrumbs in supermarket",
                          action: "Click",
                          value: `breadcrumb: ${breadcrumbs?.all_parents?.parent?.name}`,
                        });
                      }}
                    >
                      {breadcrumbs?.all_parents?.parent?.name}
                    </span>
                  </>
                )}
                {breadcrumbs?.all_parents?.name && (
                  <>
                    <i class="fas fa-angle-right"></i>
                    <span
                      onClick={() => {
                        navigate(-1);
                        ReactGA.event({
                          category: "Click on breadcrumbs in supermarket",
                          action: "Click",
                          value: `breadcrumb: ${breadcrumbs?.all_parents?.parent?.name}`,
                        });
                      }}
                    >
                      {breadcrumbs?.all_parents?.name}
                    </span>
                  </>
                )}

                <i class="fas fa-angle-right"></i>
                {breadcrumbs?.name}
              </span>

              <ul
                className="inline-list search subermarket_categories_slider"
                ref={ref}
                onMouseDown={onMouseDown}
              >
                {checkAll == "all" && (
                  <li
                    className={selected === params.id && "active"}
                    onClick={() => {
                      setSelected(params.id);
                      setAll("all");
                      window.scrollTo({
                        top: 0,
                        left: 0,
                      });
                    }}
                  >
                    <div className="category_info">
                      <ImageSuperMarket src={fallbackImage} />
                      <p>{t("All")}</p>
                    </div>
                  </li>
                )}
                {data?.pages[0]?.data?.data?.categories?.map((cat, idx) => {
                  return (
                    <li
                      className={selected == cat.id && "active"}
                      onClick={() => {
                        setSelected(cat.id);
                        setAll("");
                        window.scrollTo({
                          top: 0,
                          left: 0,
                        });
                        ReactGA.event({
                          category: "Select category in supermarket",
                          action: "Click",
                          value: `category name: ${cat?.name}}`,
                        });
                      }}
                      key={idx}
                    >
                      <div className="category_info">
                        <ImageSuperMarket src={cat.image} />
                        <p>{cat?.name}</p>
                      </div>
                    </li>
                  );
                })}
              </ul>
            </div>
            <div className="subermarket_items" ref={subermarketItemsRef}>
              {data?.pages?.map((page) =>
                page?.data?.data?.items?.data?.map((item) => {
                  return (
                    <div
                      className={`add-post-container  add-cart ${typeList === "th" ? "th" : ""
                        }`}
                    >
                      <SupermarketCartPost
                        item={item}
                        pharmacyId={params.supermarket_id}
                        theme={theme}
                      />
                    </div>
                  );
                })
              )}
              <div style={{ width: "100%" }}>
                <div
                  className="ref_loading "
                  ref={loadMoreRef}
                  onClick={() => fetchNextPage()}
                  disabled={!hasNextPage || isFetchingNextPage}
                ></div>
                <div>
                  {isFetchingNextPage ? (
                    <div class="d-flex justify-content-center">
                      <div class="spinner-border" role="status">
                        <span class="visually-hidden"></span>
                      </div>
                    </div>
                  ) : hasNextPage ? (
                    <div class="d-flex justify-content-center">
                      <div class="spinner-border" role="status">
                        <span class="visually-hidden"></span>
                      </div>
                    </div>
                  ) : (
                    ""
                  )}
                </div>
              </div>
            </div>

            <ViewCart room_uuid={room_uuid} />
          </>
        )}
      </main>
      <Modal
        show={show}
        size="lg"
        onHide={handleClose}
        className="view-cart-modal"
      >
        <Modal.Body>
          <Button className="close-btn" onClick={handleClose}>
            <i className="fa fa-times-circle-o"></i>
          </Button>
          <ViewPharmacyCartModal />
        </Modal.Body>
      </Modal>
    </div>
  );
}
export default SupermarketSubMenu;
