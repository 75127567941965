import axios from "axios";
import { API_URL } from "../constants/api";
import { useQuery } from "react-query";

const APIURL = axios.create({
  baseURL: API_URL,
});

const Orders = async (uuid, lang) => {
  return await APIURL.get(`/${uuid}/orders/room-orders`, {
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      lang: lang,
    },
  });
};
export const ShowOrders = (uuid, lang) => {
  return useQuery(`Orders-${lang}`, () => Orders(uuid, lang), {
    refetchInterval: 5000,
  });
};
