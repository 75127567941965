import React from "react";
import { Link, useLocation, useNavigate, useParams } from "react-router-dom";
import { ShowConcierge } from "../../API/Concierge";
import NewLoader from "../../new-components/title/Loader/NewLoader";
import Title from "../../new-components/title/Title";
import Overllay from "../../assets/new_imgs/Rectangle 1072.png";
import NewFooter from "../../NewFooter";

function ConceirgeServices() {
  const navigate = useNavigate();
  const location = useLocation();
  const rawPath = location.pathname;


  const room_uuid = rawPath.split("/")[1];

  const lang = localStorage.getItem("lang");
  let concierges;
  let text;
  const params = useParams();
  const { data, isLoading } = ShowConcierge(room_uuid, lang);
  if (!isLoading) {
    if (data.data.status === false) {
      navigate(`/${room_uuid}/qr-scan`);
    } else {
      let newData = data.data.data;
      concierges = newData?.concierge;
      text = newData?.title;
    }
  }
  return (
    <div className="food pb-5">
      <Title title={text} />
      <div className="box_food">
        {isLoading ? (
          <NewLoader />
        ) : (
          concierges?.map((x, idx) => {
            return (
              <Link key={idx} to={`/${params.room_uuid}/${x.slug}?theme=true`}>
                <img src={x?.image} alt="" className="booking_img" />
                <img src={Overllay} alt="" className="overlay" />
                <div className="text_food">
                  <h5 className="">{x.name}</h5>
                </div>{" "}
              </Link>
            );
          })
        )}
      </div>
      <NewFooter />
    </div>
  );
}
export default ConceirgeServices;
