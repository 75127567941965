import React from "react";
import { Zoom } from "react-reveal";
import { Link } from "react-router-dom";
import ImageSuperMarket from "../ImageSuperMarket";

const PhCategories = ({ category, room_uuid, url, apiID, apiUUID, type }) => {
  return (
    <li key={category.id}>
      <Zoom>
        <Link

          to={apiID == "pharmacy_id" ?
            category.categories_count > 0 ?
              `/${room_uuid}/pharmacy/category/${category[apiUUID]}/id/${category[apiID]}?id=${category.parent_id}&name=${category?.name}`
              : `/${room_uuid}/${url}/${category[apiID]}/${category[apiUUID]}/${category.id}`
            : `/${room_uuid}/${url}/${category[apiID]}/${category[apiUUID]}/${category.id}`}
        >
          <div
            className="img-pnl"
            style={{ backgroundImage: `url(${encodeURI(category.image)})` }}
          ></div>
          {type == "supermarket" ? (
            <ImageSuperMarket src={category.image} />
          ) : (
            <img src={encodeURI(category.image)} />
          )}
          <div></div>
          <div className="bg-layer"></div>
          {type == "supermarket" ? (
            <p className="text-black">{category.name}</p>
          ) : (
            <p>{category.name}</p>
          )}
        </Link>
      </Zoom>
    </li>
  );
};

export default PhCategories;
