import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import axios from "../../axios";
import SpaLoader from "../../Components/Loaders/SpaLoader";
import { Zoom } from "react-reveal";
import Title from "../../new-components/title/Title";
import NewFooter from "../../NewFooter";

function SalonService() {
  const [loading, setLoading] = useState(false);
  const [salons, setSalons] = useState([]);
  const [title, setTitle] = useState();
  const location = useLocation();
  const rawPath = location.pathname;
  const room_uuid = rawPath.split("/")[1];
  const navigate = useNavigate();


  useEffect(() => {
    const getData = async () => {
      setLoading(true);
      axios
        .get(`/${room_uuid}/salon_at_home`)
        .then((res) => {
          if (res.data.status === false) {
            navigate(`/${room_uuid}/qr-scan`);
          } else {
            const data = res.data.data;

            setSalons(data.salons);
            setTitle(data.center_header);
            setLoading(false);
            if (data?.salons.length === 1) {
              navigate(`/${room_uuid}/salon_at_home/${data.salons[0].uuid}`, {
                replace: true,
                state: { theme: true },
              });
            }
          }
        })
        .catch((err) => {
          if (err?.response.status === 404) {
            navigate(`/not-found`);
          }
        });
    };
    getData();
  }, [room_uuid, navigate]);

  useEffect(() => {
    window.scrollTo({
      top: 0,
      left: 0,
    });
  }, []);
  return (
    <div className="food pb-2">
      <Title title={title} />
      <main className="home-main bg-white back-white pad-top-0">
        {loading ? (
          <SpaLoader />
        ) : (
          salons?.map((salon, idx) => {
            return (
              <div key={idx}>
                <Zoom>
                  <div
                    className="con-img-container v3"
                    onClick={() =>
                      navigate(`/${room_uuid}/salon_at_home/${salon.uuid}`, {
                        state: { theme: true },
                      })
                    }
                  >
                    <div className="con-img">
                      <img src={salon.image} alt="" />
                      <p></p>
                    </div>
                  </div>
                  <div className="con-text-container">
                    <p>{salon.name}</p>
                    <hr className="mb-0 dark" />
                  </div>
                </Zoom>
              </div>
            );
          })
        )}
      </main>
      <NewFooter />
    </div>
  );
}
export default SalonService;
