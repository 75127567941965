import React from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { useEffect } from "react";
import SpaLoader from "../../Components/Loaders/SpaLoader";
import { Zoom } from "react-reveal";
import { ShowHH_HouseKeeping } from "../../API/HH_HouseKeepingAPI";
import Title from "../../new-components/title/Title";
import NewFooter from "../../NewFooter";

function HhHousekeeping() {
  const location = useLocation();
  const rawPath = location.pathname;
  const room_uuid = rawPath.split("/")[1];
  const navigate = useNavigate();

  const lang = localStorage.getItem("lang");

  let flowers;
  let text;
  let entertainmentCategories;
  const { data, isLoading, error } = ShowHH_HouseKeeping(room_uuid, lang);
  if (!isLoading) {
    console.log("ShowHH_HouseKeeping", data);
    if (error) {
      if (error?.response.status === 404) {
        navigate(`/not-found`);
      }
    } else {
      if (data.data.status === false) {
        navigate(`/${room_uuid}/qr-scan`);
      } else {
        flowers = data.data.data;
        entertainmentCategories = flowers?.companies;
        text = flowers?.title;
        if (entertainmentCategories?.length === 1) {
          navigate(
            `/${room_uuid}/hh-house-keeping-companies/services/${entertainmentCategories[0].uuid}`,
            { replace: true, state: { theme: true } }
          );
        }
      }
    }
  }
  useEffect(() => {
    window.scrollTo({
      top: 0,
      left: 0,
    });
  }, []);

  return (
    <div className="food">
      <Title title={text} />
      <main className="home-main bg-white back-white pad-top-0">
        <div className="">
          {isLoading ? (
            <SpaLoader />
          ) : entertainmentCategories.length > 1 ? (
            entertainmentCategories.map((x, idx) => {
              return (
                <div id={x.name} key={idx}>
                  <Zoom>
                    <div
                      className="con-img-container v3"
                      onClick={() =>
                        navigate(
                          `/${room_uuid}/hh-house-keeping-companies/services/${x.uuid}`,
                          {
                            state: { theme: true },
                          }
                        )
                      }
                    >
                      <div className="con-img">
                        <img src={x.logo} alt="" />
                        <p></p>
                      </div>
                    </div>
                    <div className="con-text-container">
                      <p>{x.name}</p>
                      <hr className="mb-0 dark" />
                    </div>
                  </Zoom>
                </div>
              );
            })
          ) : null}
        </div>
      </main>
      <NewFooter />
    </div>
  );
}
export default HhHousekeeping;
