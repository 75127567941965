import React, { useEffect, useRef, useState } from "react";
import { Link, useLocation, useNavigate, useParams } from "react-router-dom";
import BannerHome from "../../new-components/banner-home/BannerHome";
import NewLoader from "../../new-components/title/Loader/NewLoader";
import Search from "../../Components/Search";
import "./FoodAndBeverages.scss";
import {
  ShowRestaurantSubCategories,
} from "../../API/In-Room-DiningAPI";
import Title from "../../new-components/title/Title";
import NewFooter from "../../NewFooter";
import { t } from "i18next";

function InDiningSubCategories() {
  const location = useLocation();
  const rawPath = location.pathname;
  const room_uuid = rawPath.split("/")[1];
  const params = useParams();
  const navigate = useNavigate();

  let isbooking = rawPath.includes("restaurant-booking");
  let lang = localStorage.getItem("lang");
  let restaurant;
  let restaurantCategories;
  let images = [];
  let isVideo = false;
  let terms_and_conditions;
  let has_combo_meals;
  let text;
  let restaurant_info;
  const { data, isLoading, error } = ShowRestaurantSubCategories(
    params.room_uuid,
    params.restaurant_uuid,
    lang,
    Number(params.cat),
    (isbooking = isbooking ? "&link_type=menu" : "")
  );

  if (!isLoading) {
    if (data.data.status === false) {
      navigate(`/${room_uuid}/qr-scan`);
    } else if (!error) {
      restaurant = data.data.data;
      restaurantCategories = restaurant.categories;
      terms_and_conditions = restaurant.restaurant.terms_and_conditions;

      isVideo = restaurant.restaurant.real_video ? true : false;
      if (restaurant.restaurant.real_video) {
        if (restaurant.banners.length > 0) {
          images = [
            { image: restaurant.restaurant.real_video },
            ...restaurant.banners,
          ];
        }
      } else {
        if (restaurant.banners.length > 0) {
          images = restaurant.banners;
        }
      }
      restaurant_info = restaurant.restaurant;
      text = data.data.data.center_header;

      if (restaurantCategories?.length === 1) {
        if (isbooking) {
          if (restaurantCategories[0]?.count_sub_categories !== 0) {
            navigate(
              `/${room_uuid}/RestaurantBooking/RestaurantSubCat/${params.id}/${restaurantCategories[0].id}`,
              { replace: true }
            );
          } else {
            navigate(
              `/${room_uuid}/RestaurantBooking/RestaurantSubMenu/${params.id}/${restaurantCategories[0].id}`,
              { replace: true }
            );
          }
        } else {
          if (restaurantCategories[0]?.count_sub_categories !== 0) {
            navigate(
              `/${room_uuid}/food-and-beverages/in-room-dining/${params.restaurant_uuid}/${restaurantCategories[0].id}`,
              { replace: true }
            );
          } else {
            navigate(
              `/${room_uuid}/food-and-beverages/restaurant-booking/${params.restaurant_uuid}/category/${restaurantCategories[0].id}`,
              { replace: true }
            );
          }
        }
      }
    }
  }

  useEffect(() => {
    window.scrollTo({
      top: 0,
      left: 0,
    });
  }, []);


  const imageref = useRef();
  const [height_res, setheight_res] = useState();
  useEffect(() => {
    if (imageref.current) {
      setheight_res(imageref.current.clientWidth);
    }
  }, [data]);

  return (
    <div className="food pb-5">
      <Title title={text} />
      {isLoading ? (
        <NewLoader />
      ) : (
        <>
          {images?.length > 0 && (
            <BannerHome
              className={"bot_rad"}
              headerImages={images}
              isVideo={isVideo}
              isOverllay={true}
            />
          )}

          <div className={`res_info ${images?.length === 0 ? "mt-0" : "m"}`}>
            <div className="res_name">
              {restaurant_info?.show_booking === 1 &&
                <h5 className="">
                  {lang === "EN"
                    ? restaurant_info?.display_name
                    : restaurant_info?.name}
                </h5>
              }
              <p>
                {restaurant_info?.cuisines?.map((cuisine, idx) => {
                  if (idx + 1 < restaurant_info?.cuisines.length) {
                    return `${cuisine.name}  |  `;
                  } else {
                    return `${cuisine.name}  `;
                  }
                })}
              </p>
            </div>
            {restaurant_info?.show_booking === 1 && (
              <button
                onClick={() =>
                  navigate(
                    `/${params.room_uuid}/BookTable/${restaurant_info.uuid}/${restaurant_info.id}?theme=true`
                  )
                }
                className="reg-btn"
              >
                {t("Book a Table")}
              </button>
            )}
          </div>

          <Search
            placeholder="Search for Categories..."
            room_uuid={room_uuid}
            category="Restaurant"
            redirect="search"
          />
          {terms_and_conditions !== "" &&
            (terms_and_conditions !== undefined ? (
              <>
                <div
                  className="mx-4 divhtml"
                  dangerouslySetInnerHTML={{
                    __html: terms_and_conditions,
                  }}
                />
              </>
            ) : null)}
          <ul
            className={`${restaurant_info?.squared_categories === 1 ? "res_sp" : ""
              }  display-list two-items restaurant-list mt-2 v2 ${restaurant_info?.resturant_name.includes("McDonald")
                ? "mcdonald"
                : ""
              } `}
          >
            {restaurantCategories
              ? restaurantCategories.map((category, idx) => {
                return (
                  <li
                    key={idx}
                    className={
                      category?.is_closed
                        ? "closed restaurant-option"
                        : "restaurant-option"
                    }
                  >
                    <Link
                      to={
                        category.count_sub_categories !== 0
                          ? `/${room_uuid}/food-and-beverages/in-room-dining/${params.restaurant_uuid}/${category.id}`
                          : `/${room_uuid}/food-and-beverages/in-room-dining/${params.restaurant_uuid}/category/${category.id}`


                      }
                      ref={imageref}
                      style={
                        restaurant_info?.squared_categories === 1
                          ? { height: `${height_res}px` }
                          : { height: `140px` }
                      }
                    >
                      <div className="closed-tag"></div> <div></div>
                      {category?.is_closed && (
                        <span className="open_at">
                          {category.opens_at
                            ? `Opens at ${category.opens_at}`
                            : `Closed`}
                        </span>
                      )}
                      <div
                        className="img-pnl"

                      >
                        <img src={encodeURI(category.image)} alt="" />
                      </div>
                      <div className="bg-layer"></div>
                      <p>{category.name}</p>
                    </Link>
                  </li>
                );
              })
              : null}
          </ul>
        </>
      )}
      <NewFooter />
    </div>
  );
}
export default InDiningSubCategories;
