import React, { useEffect, useState, useMemo } from "react";
import axios from "../../axios";
import { ProgressSpinner } from "primereact/progressspinner";
import { useLocation, useNavigate } from "react-router-dom";
import taxi from "./../../assets/images/taxi/taxi.png";
import searchTaxi from "./../../assets/images/taxi/searchtaxi.svg";
import call from "./../../assets/images/taxi/call.png";
import car from "./../../assets/images/taxi/car.png";
import {
	DirectionsRenderer,
	GoogleMap,
	useJsApiLoader,
	Marker,
} from "@react-google-maps/api";
import { Button, Modal, Spinner } from "react-bootstrap";
import { t } from "i18next";
import { FaPhone } from "react-icons/fa6";
import SpaLoader from "../../Components/Loaders/SpaLoader";
const LIBRARIES = ["places"];

/**
 * 1. Fetch orders (getOrder), if order is found, get order id. else keep polling for order data.
 * 2. Query order by id (getTrackOrder) to get its status and driver location.
 * 3. Update driver location and order status according to getTrackOrder.
 * 4. Render Direction between the driver location and current destination.
 * 5. Current driver destination depends on order status
 * 		- orderStatus == "driving" -> currentDestination = customerSource;
 * 		- orderStatus == "waiting" || orderStatus == "on the way" -> currentDestination = customerDestination
 * 6. When driver location updates render the direction between driverLocation and currentDestination.
 * 7. Only one direction path is rendered at a time.
 */

const RedirectTaxi = () => {
	const location = useLocation();
	const rawPath = location.pathname;
	const room_uuid = rawPath.split("/")[1];
	const queryParams = new URLSearchParams(location.search);
	const order_number = queryParams.get("order_number");
	const [loading, setLoading] = useState(true);
	const [cancelLoading, setCancelLoading] = useState(false);
	const [open, setOpen] = useState(false);

	const handleClose = () => {
		setOpen(false);
	};

	const [orderDetail, setOrderDetail] = useState(null);
	const [map, setMap] = useState(/** @type google.maps.Map */ (null));

	const [driverLocation, setDriverLocation] = useState(null);
	const [sourceLocation, setSourceLocation] = useState(null);
	const [destinationLocation, setDestinationLocation] = useState(null);
	const [orderStatus, setOrderStatus] = useState(null);
	const [markers, setMarkers] = useState([]);

	const [second, setSecond] = useState();
	const [seconds, setSeconds] = useState(60);
	const [minutes, setMinutes] = useState();
	const [waitTime, setWaitTime] = useState();
	/**
	 * @typedef {object} TaxiOrder The open orders
	 * @property {{geopoint: string[]}} source Location of passenger
	 * @property {{geopoint: string[]}} destination Location of the passenger destination
	 * @property {"search" | "driving"} status The status of the order
	 * @property {{location: string[]}} order_tracking The coordinates of the driver location
	 */

	const [counter, setCounter] = useState(0);

	// real server
	const getData = async () => {
		try {
			const res = await axios.get(
				`/${room_uuid}/hh_taxi/open-orders?order_number=${order_number}`,
				{}
			);

			if (res.data.status === false) {
				// navigate(`/${room_uuid}/qr-scan`);
				return [];
			} else {
				const data = res.data.data;
				setOrderStatus(res?.data?.data?.orders[0]?.status);
				if (res?.data?.data?.orders[0]?.status) {
					setSourceLocation({
						lat: Number(
							res?.data?.data?.orders[0]?.source?.geopoint[1]
						),
						lng: Number(
							res?.data?.data?.orders[0]?.source?.geopoint[0]
						),
					});

					setDestinationLocation({
						lat: Number(
							res?.data?.data?.orders[0]?.destination?.geopoint[1]
						),
						lng: Number(
							res?.data?.data?.orders[0]?.destination?.geopoint[0]
						),
					});

					if (res?.data?.data?.orders[0]?.status == "driving") {
						setDriverLocation({
							lat: Number(
								res?.data?.data?.orders[0]?.order_tracking
									?.location[1]
							),
							lng: Number(
								res?.data?.data?.orders[0]?.order_tracking
									?.location[0]
							),
						});
					}

					setOrderDetail(res?.data?.data?.orders[0]);

					setSeconds(60);
					setLoading(false);
				}
				return res?.data?.data?.orders || [];
			}
		} catch (error) {
			console.error("Error in getData:", error);
			return [];
		}
	};
	useEffect(() => {
		if (orderStatus == "search") {
			renderDirection(
				sourceLocation,
				destinationLocation,
				orderDetail?.stops
			);
			setMarkers([sourceLocation, destinationLocation]);
		}
		if (orderStatus === "driving") {
			renderDirection(driverLocation, sourceLocation);
			setMarkers([sourceLocation]);
		} else if (orderStatus == "waiting") {
			renderDirection(driverLocation, destinationLocation);
			setMarkers([destinationLocation]);
		}
		if (orderStatus == "transporting") {
			renderDirection(driverLocation, destinationLocation);
			setMarkers([destinationLocation]);
		}
		// if (orderStatus == "complete") {
		// navigate(`/${room_uuid}/qr-scan`);
		// }
	}, [
		driverLocation,
		orderStatus,
		sourceLocation,
		destinationLocation,
		orderDetail,
	]);

	const getTrackOrder = async () => {
		// setLoading(true);
		axios
			.post(
				`/${room_uuid}/hh_taxi/track-order`,
				{ order_number: orderDetail?.order_number },
				{}
			)
			.then((res) => {
				if (res.data.status === false) {
					// navigate(`/${room_uuid}/qr-scan`);
				} else {
					const data = res.data.data;
					setOrderStatus(res?.data?.data?.order_tracking?.status);
					setDriverLocation({
						lat: res?.data?.data?.order_tracking?.location[1],
						lng: res?.data?.data?.order_tracking?.location[0],
					});

					const remainingSeconds =
						res?.data?.data?.order_tracking?.time_left_raw % 60;
					const minutes = Math.floor(
						res?.data?.data?.order_tracking?.time_left_raw / 60
					);
					setMinutes(minutes);
					setSecond(remainingSeconds);
					setWaitTime(`${minutes} min ${remainingSeconds} seconds`);
					setLoading(false);
				}
			})
			.catch((err) => {
				setLoading(false);

				if (err?.res.status == 404) {
					// navigate(`/not-found`);
				}
			});
	};

	const navigate = useNavigate();

	const { isLoaded } = useJsApiLoader({
		googleMapsApiKey: "AIzaSyCGRRVL7FlEoJNCN9Ljk8xo4dF9k2pNu_I",
		libraries: LIBRARIES,
	});

	const [directionsResponse, setDirectionsResponse] = useState(null);

	const [directionsRenderer, setDirectionsRenderer] = useState(null);

	/**
	 * Render the direction on google maps between origin and destination
	 *
	 * @param {{lat: number, lng: number}} origin
	 * @param {{lat: number, lng: number}} destination
	 * @param {{lat: number, lng: number}[]} stops
	 */
	const renderDirection = async (origin, destination, stops) => {
		let waypoints = [];

		if (Array.isArray(stops)) {
			waypoints = stops.map((point) => ({
				location: new window.google.maps.LatLng(
					Number(point?.lat),
					Number(point?.lng)
				),
				stopover: true,
			}));
		}

		const directionsService = new window.google.maps.DirectionsService();

		const route = await directionsService.route({
			origin: {
				lat: Number(origin?.lat),
				lng: Number(origin?.lng),
			},
			destination: {
				lat: Number(destination?.lat),
				lng: Number(destination?.lng),
			},
			waypoints,
			travelMode: window.google.maps.TravelMode.DRIVING,
			optimizeWaypoints: true,
		});

		setDirectionsResponse(route);
	};

	useEffect(() => {
		if (isLoaded) {
			console.log("is loaded");
			getData();
		}
	}, [isLoaded]);
	// run when status search run one after 2sc and get see the status when  status change to driving set set driver location
	// get track order edit driver  info

	// useEffect(() => {
	// 	if (!orderStatus && isLoaded) {
	// 		console.log("!orderStatus", orderStatus);
	// 		const intervalId = setInterval(async () => {
	// 			await getData(); // 60000ms = 1 minute
	// 		}, 4000);
	// 		return () => clearInterval(intervalId);
	// 	}
	// }, [orderDetail, orderStatus]);

	useEffect(() => {
		let isPolling = true; // To stop polling when needed
		const POLLING_INTERVAL = 5000; // Poll every 5 seconds
		const TIMEOUT = 30000; // Stop polling after 30 seconds
		let timeoutId;

		const fetchOrders = async () => {
			try {
				const data = await getData();

				if (data && data.length > 0) {
					isPolling = false;
				}
			} catch (error) {
				isPolling = false;
			}
		};

		const startPolling = () => {
			timeoutId = setTimeout(() => {
				if (isPolling) {
					fetchOrders().then(() => {
						if (isPolling) {
							startPolling();
						}
					});
				}
			}, POLLING_INTERVAL);
		};

		fetchOrders();
		startPolling();

		return () => {
			isPolling = false;
			clearTimeout(timeoutId);
		};
	}, []);

	useEffect(() => {
		if (orderStatus === "search") {
			setTimeout(getData, 5000); // 60000ms = 1 minute
		} else if (orderStatus == "driving") {
			getTrackOrder();
		}
	}, [orderDetail, orderStatus]);

	useEffect(() => {
		if (driverLocation) {
			setTimeout(getTrackOrder, 5000); // 60000ms = 1 minute
		}
	}, [driverLocation]);

	const canselOrder = () => {
		// setLoading = true;
		setCancelLoading(true);
		axios
			.post(
				`/${room_uuid}/hh_taxi/cancel-order`,
				{ order_number: orderDetail?.order_number },
				{}
			)
			.then((res) => {
				if (res.data.status === false) {
					setCancelLoading(false);

					// navigate(`/${room_uuid}/qr-scan`);
				} else {
					setCancelLoading(false);
					const data = res.data.data;
					navigate(`/${room_uuid}/qr-code`);
					console.log("cancel order res", res);
				}
			})
			.catch((err) => {
				// setLoading(false);
				setCancelLoading(false);

				if (err?.response.status == 404) {
					// navigate(`/not-found`);
				}
			})
			.finally(() => {
				setCancelLoading(false);

				// setLoading(false);
			});
	};

	const [addressMark, setAddressMark] = useState({
		address: "",
		lat: 0,
		lng: 0,
	});

	const mapContainerStyle = {
		width: "100%",
		height: "90vh",
	};
	if (!orderStatus) {
		return (
			<div
				className="d-flex justify-content-center align-items-center"
				style={{ height: "100vh" }}
			>
				<ProgressSpinner></ProgressSpinner>
			</div>
		);
	}
	if (!isLoaded) {
		return <SpaLoader />;
	}

	return !isLoaded ? (
		<SpaLoader />
	) : (
		<div className="yango-page">
			<GoogleMap
				mapContainerStyle={mapContainerStyle}
				zoom={17}
				center={sourceLocation}
				options={{
					zoomControl: false,
					streetViewControl: false,
					mapTypeControl: false,
					fullscreenControl: false,
				}}
				onLoad={(map) => setMap(map)}
			>
				{markers.map((marker) => (
					<Marker position={marker} />
				))}
				{driverLocation && (
					<Marker position={driverLocation} icon={car}></Marker>
				)}
				<DirectionsRenderer
					directions={directionsResponse}
					options={{
						suppressMarkers: true, // إخفاء علامات البداية والنهاية
					}}
				/>
			</GoogleMap>
			{orderStatus == "expired" && (
				<div className="map-opacity">
					<div className="box">
						<h4>Order is Expired</h4>
					</div>
				</div>
			)}
			{orderStatus == "search" && (
				<div className="map-opacity">
					<div style={{ width: "100%", height: "100%" }}>
						<div
							style={{
								position: "absolute",
								top: "40%",
								left: "50%",
								transform: "translate(-50%, -50%)", // Center the div
								zIndex: 1000,
								textAlign: "center",
								width: "100%",
								height: "auto",
							}}
						>
							<img
								src={searchTaxi}
								alt="Search Icon"
								style={{
									display: "block",
									margin: "0 auto", // Center the image horizontally
								}}
							/>
							<p
								style={{
									margin: "10px 0 0",
									fontSize: "20px",
									color: "#333",
								}}
								className="font-weight-bold"
							>
								Looking for a car
							</p>
							<p
								style={{
									margin: "5px 0 20px",
									fontSize: "14px",
									color: "#666",
								}}
							>
								It may take a few moments
							</p>
							<img
								src={taxi}
								alt="Taxi Image"
								style={{
									display: "block",
									margin: "50px auto 0", // Center the image horizontally
									width: "auto", // Adjust the width
									height: "350px",
								}}
							/>
						</div>
					</div>
				</div>
			)}
			{orderStatus === "search" && (
				<div className="card">
					<div class="card-body">
						<div className="ord_btn">
							<button onClick={() => setOpen(true)}>
								{" "}
								Cancel
							</button>
						</div>
					</div>
				</div>
			)}
			{(orderStatus != "cancelled" || orderStatus == "expired") &&
			(orderStatus === "waiting" ||
				orderStatus === "driving" ||
				orderStatus === "transporting" ||
				orderStatus == "complete") ? (
				<div>
					{
						<div className="card card-driver-info">
							<div class="card-body">
								<div className="d-flex" style={{ gap: "20px" }}>
									{(orderStatus == "driving" ||
										orderStatus == "transporting") && (
										<div>
											<span className="fw-lighter text-secondary">
												Remaining Time
											</span>
											<br />
											{minutes > 0 && (
												<span>{minutes} min </span>
											)}{" "}
											{second && (
												<span>{second} second</span>
											)}
										</div>
									)}
									{orderStatus == "waiting" && (
										<div>The Driver has arrived</div>
									)}
									{orderStatus == "complete" && (
										<div>Your trip has been completed</div>
									)}
								</div>

								<hr
									style={{
										margin: "15px 0",
										padding: "0",
										width: "100%",
									}}
								/>
								<h6 className="card-subtitle text-body-secondary m-0">
									Car details
								</h6>
								<div className="d-flex justify-content-between align-items-center my-1">
									<div className="d-flex flex-column">
										<span className="fw-lighter text-secondary">
											Driver
										</span>
										<span>
											{orderDetail?.car_details?.fullname}
										</span>
										<span>
											{orderDetail?.car_details?.phone}
										</span>
									</div>
									<a
										href={`tel:${orderDetail?.car_details?.phone}`}
									>
										<button className="btn bg-call-button ">
											<img src={call} alt="call" />
										</button>
									</a>
								</div>

								<div
									className="d-flex "
									style={{ gap: "20px" }}
								>
									<div>
										<span className="fw-lighter text-secondary">
											Car Model
										</span>
										<br />
										<span>
											{
												orderDetail?.car_details
													?.vehicle?.model
											}
										</span>
									</div>
									<div>
										<span className="fw-lighter text-secondary">
											Car Number
										</span>
										<br />
										<span>
											{" "}
											{
												orderDetail?.car_details
													?.vehicle?.number
											}
										</span>
									</div>
								</div>
								<div className="ord_btn">
									<button onClick={() => setOpen(true)}>
										{" "}
										Cancel
									</button>
								</div>
							</div>
						</div>
					}
				</div>
			) : null}
			<Modal
				show={open}
				onHide={handleClose}
				backdrop="static"
				className={` rate_modal  p-0`}
				style={{
					minHeight: "150px",
					padding: "0px !important",
					display: "flex !important",
					alignItems: "center !important",
					paddingRight: "0 !important",
				}}
			>
				<Modal.Body style={{ padding: "20px 10px", height: "auto" }}>
					{!cancelLoading ? (
						<>
							<h6> {orderDetail?.cancel_rules?.message}</h6>
							<p>Are You Sure You Want To Cancel This Order</p>
							<div className="order_t">
								<Button
									className="no"
									onClick={() => setOpen(false)}
								>
									{t("No")}
								</Button>
								<Button className="yes" onClick={canselOrder}>
									{t("Yes")}
								</Button>
							</div>
						</>
					) : (
						<div>
							<Spinner animation="border" />
						</div>
					)}
				</Modal.Body>
			</Modal>
		</div>
	);
};

export default RedirectTaxi;
