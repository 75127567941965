import React, { useContext, useState } from "react";
import { Button, Modal, Dropdown, Form, Row, Col } from "react-bootstrap";
import HeaderInner from "../../Components/HeaderInner";
import CarBookModal from "../../Components/CarBookModal";
import { Link, useLocation, useNavigate, useParams } from "react-router-dom";
import PhoneInput from "react-phone-input-2";
import axios from "../../axios";
import { useEffect } from "react";
import { object, string, number, date, InferType } from "yup";
import SpaLoader from "../../Components/Loaders/SpaLoader";
import { useTranslation } from "react-i18next";
import ReactGA from "react-ga4";
import NewFooter from "../../NewFooter";
import Title from "../../new-components/title/Title";
import BannerServices from "../../new-components/banner-home/BannerServices";
import { phoneFormat } from "../../constants/api";
import PhoneInputComponent from "../../Components/PhoneInputComponent";
import { ThemeContext } from "../../context/ThemeContext";

function HealthCareOrder() {
  const [show, setShow] = useState(false);
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  const [phone, setPhone] = useState();
  const handleClose = () => setShow(false);
  const handleShow = () => {
    setShow(true);
    ReactGA.event({
      category: "Open popup HealthCare",
      action: "Click",
      value: `HealthCare: ${name}`,
    });
  };
  // const new_design = localStorage.getItem("new_design");
  // const theme = new_design == "true";
  const { theme } = useContext(ThemeContext);
  const params = useParams();
  const [headerImage, setHeaderImage] = useState();
  const [header_title, setheader_title] = useState();
  const [description, setDescription] = useState();
  const [isDisabled, setIsDisabled] = useState(false);
  const [name, setName] = useState("");
  const [img, setImg] = useState("");
  const location = useLocation();
  const rawPath = location.pathname;
  const room_uuid = rawPath.split("/")[1];
  const [careOrder, setCareOrder] = useState({
    name: "",
    phone: "",
    email: "",
    note: "",
    category_id: "",
  });
  const { t, i18n } = useTranslation();
  const lang = localStorage.getItem("lang");

  let userSchema = object({
    name: string(),
    phone: string(),
    email: string().email(),
    note: string(),
    category_id: string(),
    // createdOn: date().default(() => new Date()),
  });
  const handleInputChange = (e) => {
    const { name, value } = e.target;
    // setFormData((prevFormData) => ({
    //   ...prevFormData,
    //   [name]: value,
    // }));
    setCareOrder((prev) => ({
      ...prev,
      [name]: value,
    }));
  };
  const [errName, setErrName] = useState("");
  const [errPhone, setErrPhone] = useState("");
  const [errEmail, setErrEmail] = useState("");
  const [categoryImg, setCategoryImg] = useState();
  const checkValEmail =
    careOrder.email.length >= 0 && !/\S+@\S+\.\S+/.test(careOrder.email);
  const checkInputs = () => {
    if (careOrder.name == "") {
      setErrName("The name field is required.");
    } else {
      setErrName("");
    }
    if (checkValEmail) {
      setErrEmail("The email must be a valid email address.");
    } else {
      setErrEmail("");
    }
    if (careOrder.phone == "") {
      setErrPhone("The phone field is required.");
    } else {
      setErrPhone("");
    }
  };

  const handleBook = async () => {
    checkInputs();
    if (careOrder.name != "" && careOrder.email && careOrder.phone) {
      setIsDisabled(true);
      const anser = await userSchema.validate(careOrder);
      axios
        .post(`/${room_uuid}/health_care_and_wellness/book-category`, anser, {
          headers: {
            lang: lang,
          },
        })
        .then((res) => {
          ReactGA.event({
            category: "Book service HealthCare",
            action: "Click",
            value: `HealthCare: ${name}`,
          });
          setIsDisabled(false);

          console.log("res", res);
          if (res.data.status) {
            navigate(`/${room_uuid}/HCOrderPlaced`, {
              state: { metadata: res.data.data },
            });
          }
        })
        .catch((err) => {
          setIsDisabled(false);
          console.log(err);
        });
    }
    // navigate("/HCOrderPlaced");
  };

  useEffect(() => {
    window.scrollTo({
      top: 0,
      left: 0,
    });
  }, []);

  useEffect(() => {
    const getData = async () => {
      setLoading(true);
      axios
        .get(
          `/${room_uuid}/health_care_and_wellness/category_details/${params.id}`,
          {
            headers: {
              lang: lang,
            },
          }
        )
        .then((res) => {
          if (res.data.status === false) {
            navigate(`/${room_uuid}/qr-scan`);
          } else {
            const data = res.data.data;
            setHeaderImage(data.header_images[0]);
            setheader_title(data.header_title);
            setImg(data.header_images[0]);
            setDescription(data.category.description);
            setName(data.category.name);
            setCategoryImg(data.category.image);
            setCareOrder((prev) => ({
              ...prev,
              category_id: data.category.uuid,
            }));
            setLoading(false);
          }
        });
    };
    getData();
  }, []);

  // <HeaderInner headerName={"Healthcare & Wellness"} />
  return (
    <div className={theme ? "food pb-0" : ""}>
      {theme ? (
        <Title title={header_title} />
      ) : (
        <HeaderInner headerName={header_title} />
      )}
      <main className="home-main bg-white back-white book-table pad-top-0 mb-0">
        {loading ? (
          <SpaLoader />
        ) : (
          <>
            {theme ? (
              <BannerServices headerImages={[headerImage]} />
            ) : (
              <div className="con-order-img">
                <img src={headerImage ? headerImage : categoryImg} alt="" />
              </div>
            )}
            {/* <div className="spacer-40 my-1"></div> */}

            <div className="health-details px-3">
              <div
                dangerouslySetInnerHTML={{
                  __html: description,
                }}
              />
            </div>
            <div className="spacer-40 " style={{ marginBottom: "30px" }}></div>
            <div className="padd-div text-center px-4 mb-4">
              <a
                className="reg-btn large more-pad font-dark "
                onClick={handleShow}
              >
                {t(`Book`)}
              </a>
            </div>
          </>
        )}
        <Modal
          show={show}
          onHide={handleClose}
          className={`${theme ? "food-modal" : ""} custom-width `}
        >
          <Modal.Body>
            <button
              type="button"
              className="button close-pop"
              onClick={handleClose}
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="24.054"
                height="24.625"
                viewBox="0 0 24.054 24.625"
              >
                <defs></defs>
                <path
                  id="Icon_ionic-ios-close-circle"
                  data-name="Icon ionic-ios-close-circle"
                  className="cls-50"
                  d="M15.4,3.375A12.17,12.17,0,0,0,3.375,15.687,12.17,12.17,0,0,0,15.4,28,12.17,12.17,0,0,0,27.429,15.687,12.17,12.17,0,0,0,15.4,3.375Zm3.047,16.77L15.4,17.025l-3.047,3.12a.913.913,0,0,1-1.307,0,.959.959,0,0,1,0-1.338l3.047-3.12-3.047-3.12a.959.959,0,0,1,0-1.338.913.913,0,0,1,1.307,0L15.4,14.35l3.047-3.12a.913.913,0,0,1,1.307,0,.966.966,0,0,1,0,1.338l-3.047,3.12,3.047,3.12a.966.966,0,0,1,0,1.338A.9.9,0,0,1,18.449,20.145Z"
                  transform="translate(-3.375 -3.375)"
                ></path>
              </svg>
            </button>
            <div className="body-container">
              <div className="img-pnl">
                <img className="" src={img} />
              </div>
              <div className="content-pnl">
                <div className="top">
                  <p className="title text-start">{name}</p>
                  <p className="">-</p>
                </div>
                <div className="input-pnl">
                  <Row className="justify-content-center">
                    <Col xs="12">
                      <Form.Group
                        className="mb-3 dark-placeholder i-f-small"
                        controlId="formBasicname"
                      >
                        <Form.Control
                          type="text"
                          placeholder={t(`Your Name`)}
                          name="name"
                          value={careOrder.name}
                          onChange={handleInputChange}
                        />
                      </Form.Group>
                      {errName != "" && (
                        <span className="err_msg">{t(`${errName}`)}</span>
                      )}
                    </Col>
                    <Col xs="12">
                      <Form.Group
                        className="mb-3 dark-placeholder i-f-small"
                        controlId="formBasicname"
                      >
                        <Form.Control
                          type="email"
                          placeholder={t(`Your Email`)}
                          name="email"
                          value={careOrder.email}
                          onChange={handleInputChange}
                        />
                      </Form.Group>
                      {errEmail != "" && (
                        <span className="err_msg">{t(`${errEmail}`)}</span>
                      )}
                    </Col>
                  </Row>
                  <Form.Group className="mb-3 dark-placeholder i-f-small w-100">
                    <PhoneInputComponent
                      value={careOrder.phone}
                      onChange={(phone) => {
                        setCareOrder((prev) => ({ ...prev, phone }));
                        // setPhone(phone);
                      }}
                    />
                  </Form.Group>
                  {errPhone != "" && (
                    <span className="err_msg" style={{ marginLeft: "-100px" }}>
                      {t(`${errPhone}`)}
                    </span>
                  )}
                  <Form.Group className="mb-3 dark-placeholder i-f-small w-100">
                    <Form.Control
                      as="textarea"
                      placeholder={t(`Your Notes (Optional)`)}
                      style={{ height: "106px" }}
                      name="note"
                      value={careOrder.note}
                      onChange={handleInputChange}
                    />
                  </Form.Group>
                </div>
                <div className="spacer-40"></div>
                <div className="padd-div text-center mb-2 ">
                  <Button
                    className="reg-btn book"
                    disabled={isDisabled}
                    onClick={handleBook}
                  >
                    {t(`Book`)}
                  </Button>
                </div>
              </div>
            </div>
          </Modal.Body>
        </Modal>
      </main>
      {theme && <NewFooter />}
    </div>
  );
}
export default HealthCareOrder;
