import React from "react";
import { Carousel } from "react-responsive-carousel";
import Slide1 from "../assets/images/slide-1.png";
import Slide2 from "../assets/images/slide-2.png";
import Slide3 from "../assets/images/slide-3.png";
import Slide4 from "../assets/images/slide-4.png";
import "react-responsive-carousel/lib/styles/carousel.min.css";

function CarosleRestaurant({ className, headerImages, dots, isVideo }) {
  const images = [Slide1, Slide2, Slide3, Slide4];
  // console.log("headerImages", headerImages);
  if (isVideo) {
    const first = headerImages[0];
    const second = headerImages[1];
    const images = headerImages.slice(2);
    headerImages = [second, first, ...images];
  }
  return (
    <div className={className}>
      <Carousel
        interval="4000"
        className=" noSelect"
        swipeable={true}
        autoPlay={false}
        infiniteLoop={true}
        showIndicators={false}
        showArrows={false}
        showThumbs={false}
        emulateTouch={true}
        showStatus={false}
      >
        {headerImages
          ? headerImages?.map((slide) => {
            if (
              ["mp4", "avi", "mkv", "mov", "webm"].includes(
                slide?.split(".").pop().toLowerCase()
              )
            ) {
              return (
                <div className="carousel-item-custom carousel-restaurant  noSelect">
                  <video
                    width="100%"
                    height="165"
                    autoPlay
                    muted
                    playsinline=""
                    loop
                  >
                    <source src={slide} type="video/mp4" />
                  </video>
                </div>
              );
            }
            return (
              <div className="carousel-item-custom carousel-restaurant noSelect">
                <img src={slide} alt="Slide" />
              </div>
            );
          })
          : images.map((slide) => {
            return (
              <div className="carousel-item-custom noSelect">
                <img src={slide} alt="Slide" />
              </div>
            );
          })}
      </Carousel>
    </div>
  );
}
export default CarosleRestaurant;
