import React, { useRef } from "react";
import { useState, useEffect } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import NavBar from "./Components/NavBar";
import axios from "./axios/index";
import RItems from "./Components/Search/RItems";
import RCategories from "./Components/Search/RCategories";
import RResturants from "./Components/Search/RResturants";
import Title from "./Components/Search/Title";
import { useTranslation } from "react-i18next";
import HeaderInner from "./Components/HeaderInner";
import { useDebouncedCallback } from "use-debounce";

function SearchPageScan() {
  const inputRef = useRef(null);
  const { t, i18n } = useTranslation();
  const lang = localStorage.getItem("lang");
  const location = useLocation();
  const rawPath = location.pathname;
  const room_uuid = rawPath.split("/")[1];
  const text = location.search.replaceAll("?", "").replaceAll("%20", " ");

  useEffect(() => {
    inputRef.current.focus();
  }, []);

  const navigate = useNavigate();

  const [search, setSearch] = useState("");
  const [resItems, setResItems] = useState([]);
  const [resCategories, setResCategories] = useState([]);
  const [resResturans, setResResturans] = useState([]);

  const [loadign, setLoading] = useState(false);

  const getData = () => {
    setLoading(true);
    axios
      .get(
        `/${room_uuid}/restaurant-menu/search?search_category=${encodeURIComponent(
          "Restaurant"
        )}&search=${search}`,
        {
          headers: {
            lang: lang,
          },
        }
      )
      .then((res) => {
        const data = res?.data?.data;
        setLoading(false);
        setResItems(data.rItems);
        setResCategories(data.rCategories);
        setResResturans(data.restaurants);
      });
  };

  useEffect(() => {
    if (search != "") {
      getData();
    }
  }, [search]);

  const handleInputChange = useDebouncedCallback((event) => {
    setSearch(event.target.value);
  }, 800);

  return (
    <>
      <HeaderInner scan={true} headerName={text} />
      <main className="home-main pad-top-0">
        <div className="Search-bar less searchPage mt-3">
          <input
            className="form-control"
            ref={inputRef}
            type="search"
            placeholder={t("Search")}
            style={{ backgroundColor: "#fff" }}
            onChange={handleInputChange}
          />
          <i class="fa fa-search" aria-hidden="true"></i>
        </div>

        {resItems?.length > 0 && <Title des="Items" />}
        {resItems?.length > 0 &&
          resItems?.map((item) => {
            return (
              <div
              // onClick={() =>
              //   navigate(
              //     `/${room_uuid}/reastrant/${item.restaurant_id}/${item.id}`
              //   )
              // }
              >
                <RItems item={item} room_uuid={room_uuid} />;
              </div>
            );
          })}

        {resCategories?.length > 0 && <Title des="Categories" />}
        <ul className="display-list three-items v2 v3">
          {resCategories?.length > 0 &&
            resCategories?.map((item) => {
              return (
                <RCategories
                  category={item}
                  room_uuid={room_uuid}
                  url="RestaurantSubMenu"
                />
              );
            })}
        </ul>
        {resResturans?.length > 0 && <Title des="Restaurants" />}
        <div>
          {resResturans?.length > 0 &&
            resResturans?.map((item) => {
              return (
                <RResturants
                  restaurant={item}
                  room_uuid={room_uuid}
                  url="RestaurantMenu"
                />
              );
            })}
        </div>
      </main>
    </>
  );
}
export default SearchPageScan;
