import React, { useContext, useEffect, useState } from "react";
import { ShowPharmacies } from "../API/Pharmacy";
import PharmacyLoader from "./Loaders/PharmacyLoader";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { Zoom } from "react-reveal";
import { Accordion, AccordionTab } from "primereact/accordion";
import SpaLoader from "./Loaders/SpaLoader";
import { ThemeContext } from "../context/ThemeContext";

const PharmacyComponent = ({ room_uuid, ph_uuid, ph_id }) => {
  // console.log("phuuid", ph_uuid);
  const lang = localStorage.getItem("lang");
  const navigate = useNavigate();
  let pharmacies;
  let bgColor;
  let color;
  let text;
  // let activeCategory = 0
  // let subCategories
  let terms_and_conditions;
  // const new_design = localStorage.getItem("new_design");
  // const theme = new_design == "true";

  const { theme } = useContext(ThemeContext);
  const { data, isLoading, error } = ShowPharmacies(room_uuid, ph_uuid, lang);


  if (!isLoading) {
    if (data.data.status == false) {
      navigate(`/${room_uuid}/qr-scan`);
    } else {
      pharmacies = data.data.data.categories;
      // subCategories = data.data.data.categories[0]?.sub_categories
      color = data.data.data.color;
      bgColor = data.data.data.header_color;
      text = data.data.data.center_header;
      terms_and_conditions = data.data.data?.pharmacy?.terms_and_conditions;
      console.log("pharmacies", pharmacies);
    }
  }
  const [subCategories, setSubCategories] = useState([])
  const [activeCategory, setActiveCategory] = useState(0)
  const [activeIndex, setactiveIndexy] = useState(-1)

  const location = useLocation()
  const queryParams = new URLSearchParams(location.search);
  const id = queryParams.get('id');
  const name = queryParams.get('name');
  console.log("id", id);
  console.log("name", name);
  const [loading, setLoading] = useState(true)

  useEffect(() => {
    if (data) {

      if (data && id != null || data && name) {
        console.log("id", id);
        const index = pharmacies?.findIndex((item) => {
          if (id != "null") {
            console.log("**************id", id);

            return item.id == Number(id)
          } else {
            console.log("**************name", name);

            return item.name == name
          }
        }
        );
        setActiveCategory(index)
        let acIndex = -1
        if (pharmacies?.length > 0) {
          acIndex = pharmacies[index]?.sub_categories?.findIndex((item) => item.name == name) || -1;
        }
        setactiveIndexy(acIndex)
        setLoading(false)
      } else {
        setLoading(false)

      }

    }
  }, [data, location.search])


  useEffect(() => {
    if (pharmacies) {
      setSubCategories(pharmacies[activeCategory]?.sub_categories)
      console.log("suuuub", pharmacies[activeCategory]?.sub_categories);


    }
  }, [data, pharmacies, activeCategory])

  const changeCategory = (i) => {

    setActiveCategory(i)
  }


  console.log("activeIndex", activeIndex);



  return (
    <div className="pharmacy_new">
      {terms_and_conditions != "" &&
        (terms_and_conditions != undefined ? (
          <>
            {/* <p className="text-center">Terms & Conditions</p> */}
            <div
              className="ph_des divhtml"
              dangerouslySetInnerHTML={{
                __html: terms_and_conditions,
              }}
            />
          </>
        ) : null)}
      {
        isLoading || loading ? (
          <SpaLoader />
        ) :
          <div className="pharmacy_new_box">

            <ul className="left_list">
              {pharmacies ? (
                pharmacies.map((pharmacy, i) => {
                  return (
                    <>
                      <li key={pharmacy.id} className={i == activeCategory ? "active_item" : ""}>
                        <Zoom>
                          <Link
                            // to={
                            //   pharmacy?.url.includes("?cat=") &&
                            //     pharmacy?.url.includes("pharmacy/categories")
                            //     ? `/${room_uuid}/PharmacySubCategory/${ph_uuid}/${pharmacy.id}`
                            //     :
                            //     `/${room_uuid}/PharmacySubMenu/${ph_id}/${ph_uuid}/${pharmacy.id}/`
                            // }
                            onClick={() => changeCategory(i)}
                            state={{ theme: theme }}
                          >

                            <p className="text-black">
                              {!pharmacy?.name?.includes(" ") &&
                                pharmacy?.name?.length > 12
                                ? `${pharmacy?.name?.slice(0, 12)}...`
                                : pharmacy?.name}
                            </p>{" "}
                          </Link>
                        </Zoom>
                      </li>
                    </>
                  );
                })
              ) : null}
            </ul>

            <div className="auc">
              <Zoom>
                <Accordion
                  expandIcon="fa fa-chevron-down"
                  collapseIcon="fa fa-chevron-up"
                  className="mb-4"
                  activeIndex={activeIndex}


                >
                  {
                    subCategories?.map((sub, idx) => {
                      return (
                        <AccordionTab
                          key={idx}
                          header={sub.name}
                          disabled={false}
                        >

                          <ul className="list_sub_categories">
                            {
                              sub?.sub_categories?.map((item, index) => {
                                return (
                                  <li key={item.id}>
                                    <Link to={`/${room_uuid}/PharmacySubMenu/${ph_id}/${ph_uuid}/${item.id}/`}>
                                      <img src={item?.image} alt="" /><p>{item?.name.replace("/", " ")}</p>
                                    </Link>
                                  </li>
                                )
                              })
                            }
                          </ul>
                        </AccordionTab>
                      )
                    })
                  }
                </Accordion>
              </Zoom>
            </div>
          </div>
      }

    </div>
  );
};

export default PharmacyComponent;
