import React, { useContext, useEffect, useState } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import HeaderInner from "../../Components/HeaderInner";
import SpaCarosle from "../../Components/SpaCarosle";
import axios from "../../axios";
import Green from "../../assets/images/icons/green-leaf.png";
import { Button, Modal } from "react-bootstrap";
import SpaLoader from "../../Components/Loaders/SpaLoader";
import "../../new-pages/hotel-services/HotelServices.scss";
import { useTranslation } from "react-i18next";
import ReactGA from "react-ga4";
import { Accordion, AccordionTab } from "primereact/accordion";
import TaxInclusive from "../../Components/TaxInclusive.";
import Title from "../../new-components/title/Title";
import BannerServices from "../../new-components/banner-home/BannerServices";
import HouseImage from "../../assets/images/housekeeping.jpeg";
import NewFooter from "../../NewFooter";
import { ThemeContext } from "../../context/ThemeContext";

function HouseKeeping() {
  useEffect(() => {
    window.scrollTo({
      top: 0,
      left: 0,
    });
  }, []);

  const [service, setService] = useState([]);
  const [showError, setShowError] = useState(false);
  const [loading, setLaoding] = useState(false);
  const [title, setTitle] = useState();
  const [hcServices, setHcServices] = useState();
  const [h_images, seth_images] = useState();
  const [isDisabled, setIsDisabled] = useState(false);
  const navigate = useNavigate();
  const location = useLocation();
  const rawPath = location.pathname;
  const room_uuid = rawPath.split("/")[1];
  const { t, i18n } = useTranslation();
  // const theme = rawPath.includes("hotel-services");
  // const new_design = localStorage.getItem("new_design");
  // const theme = new_design == "true";
  const { theme } = useContext(ThemeContext);
  // console.log("theme", theme);

  // console.log("hotel-services", service);

  const toggleService = (input, name, working_times) => {
    console.log("working_times", working_times);
    if (service.includes(input)) {
      ReactGA.event({
        category: "Add HouseKeeping",
        action: "Click",
        value: `HouseKeeping: ${name}`,
      });
      setService((prev) => {
        return prev.filter((x) => x !== input);
      });
    } else {
      setService((prev) => [...prev, input]);
    }
    console.log(service);
  };
  const lang = localStorage.getItem("lang");
  const currency = localStorage.getItem("currency");

  const handleBook = () => {
    if (service.length <= 0) {
      setShowError(true);
    } else {
      setIsDisabled(true);
      axios
        .post(
          `/${room_uuid}/house_keeping/book_service`,
          {
            services: service,
          },
          {
            headers: {
              lang: lang,
            },
          }
        )
        .then((res) => {
          setIsDisabled(false);
          if (res.data.status) {
            navigate(`/${room_uuid}/HKOrderPlaced`);
          }
        })
        .catch((err) => {
          setIsDisabled(false);
          console.log(err);
        });
    }
  };

  useEffect(() => {
    const getData = async () => {
      setLaoding(true);
      axios
        .get(`/${room_uuid}/house_keeping`, {
          headers: {
            lang: lang,
          },
        })
        .then((res) => {
          if (res.data.status === false) {
            navigate(`/${room_uuid}/qr-scan`);
          } else {
            const data = res.data.data;
            setHcServices(data.houseKeepingServices);
            setTitle(data.title);
            seth_images(data.banners);
            console.log(data);
            setLaoding(false);
          }
        })
        .catch((err) => {
          if (err?.response.status == 404) {
            navigate(`/not-found`);
          }
        });
    };
    getData();
  }, []);
  // const price = 100;
  return (
    <div className={theme ? "food pb-0" : ""}>
      {theme ? <Title title={title} /> : <HeaderInner headerName={title} />}
      <main className="home-main bg-white back-white pad-top-0 house">
        {loading ? (
          <SpaLoader />
        ) : (
          <>
            {theme ? (
              <BannerServices headerImages={h_images} />
            ) : (
              <SpaCarosle
                noDots={true}
                autoPlay={true}
                isSalon={true}
                salonImg={h_images}
              />
            )}
            <div className="housekeeping-container">
              <p className="service">{t("Choose a Service")}</p>
              <div className="service-container">
                {hcServices &&
                  hcServices.map((prop) => {
                    const {
                      id,
                      name,
                      is_green,
                      activeSubCategories,
                      price,
                      description,
                      working_times,
                    } = prop;

                    return (
                      <>
                        {activeSubCategories?.length > 0 ? (
                          <>
                            <Accordion
                              multiple
                              expandIcon="fa fa-plus"
                              collapseIcon="fa fa-minus"
                              className="mb-3 acor_house"
                            >
                              <AccordionTab
                                key={"Disclaimer"}
                                header={
                                  <>
                                    <span>{name}</span>
                                    {price != 0 && (
                                      <span className="house_price">
                                        {price} {currency}
                                      </span>
                                    )}
                                  </>
                                }
                                disabled={false}
                              >
                                {activeSubCategories?.map((item) => {
                                  return (
                                    <Button
                                      className={
                                        service.includes(item.id)
                                          ? `reg-btn outline-btn dark d-flex justify-content-center  ${item.is_green == 1
                                            ? "is_green"
                                            : ""
                                          }`
                                          : "reg-btn outline-btn d-flex justify-content-center "
                                      }
                                      style={
                                        service.includes(item.id)
                                          ? { backgroundClor: "#006390" }
                                          : {}
                                      }
                                      onClick={() =>
                                        toggleService(
                                          item.id,
                                          item.name,
                                          working_times
                                        )
                                      }
                                    >
                                      <span>{item.name}</span>
                                      {item?.price != 0 && (
                                        <span className="house_price">
                                          {item?.price} {currency}
                                        </span>
                                      )}
                                      {item.is_green == 1 && (
                                        <img
                                          className="green_img"
                                          src={Green}
                                          alt=""
                                          width={25}
                                          height={25}
                                        />
                                      )}
                                    </Button>
                                  );
                                })}
                              </AccordionTab>
                            </Accordion>
                            {description && (
                              <>
                                <p className="desc">{description}</p>
                              </>
                            )}
                          </>
                        ) : (
                          <>
                            <Button
                              className={
                                service.includes(id)
                                  ? `reg-btn outline-btn dark d-flex justify-content-center  ${is_green == 1 ? "is_green" : ""
                                  }`
                                  : "reg-btn outline-btn d-flex justify-content-center "
                              }
                              style={
                                service.includes(id)
                                  ? { backgroundClor: "#006390" }
                                  : {}
                              }
                              onClick={() =>
                                toggleService(id, name, working_times)
                              }
                            >
                              <span>{name}</span>
                              {price != 0 && (
                                <span className="house_price">
                                  {price} {currency}
                                </span>
                              )}
                              {is_green == 1 && (
                                <img className="green_img" src={Green} alt="" width={25} height={25} />
                              )}
                            </Button>
                            {description && (
                              <>
                                <p className="desc">{description}</p>
                              </>
                            )}
                          </>
                        )}
                      </>
                    );
                  })}
              </div>
              {showError ? (
                <p className="no-service">{t("Choose a service")} </p>
              ) : null}
              <div className="book">
                <Button
                  onClick={handleBook}
                  disabled={isDisabled}
                  className="reg-btn"
                >
                  {t("Order")}
                </Button>
                {/* <h3 className="text-center">
                  <TaxInclusive />
                </h3> */}
              </div>
            </div>
          </>
        )}
      </main>
      {theme && <NewFooter />}
    </div>
  );
}
export default HouseKeeping;
