export const validateOTP = (value, setError) => {
	// OTP validation logic (example: 6-digit numeric OTP)
	const otpPattern = /^[0-9]{6}$/;
	if (!otpPattern.test(value)) {
		setError("OTP must be a 6-digit number.");
		return false;
	}
	setError("");
	return true;
};
