import React, { useContext, useState } from "react";
import { Button, Modal, Dropdown } from "react-bootstrap";
import HeaderInner from "../../Components/HeaderInner";
import CarBookModal from "../../Components/CarBookModal";
import { Link, useLocation, useNavigate } from "react-router-dom";
import axios from "../../axios";
import { useEffect } from "react";
import SpaLoader from "../../Components/Loaders/SpaLoader";
import { Zoom } from "react-reveal";
import Bounce from "react-reveal/Bounce";
import { ShowHealthCare, ShowHealthCares } from "../../API/HealthCareAPI";
import Title from "../../new-components/title/Title";
import Overllay from "../../assets/new_imgs/Rectangle 1072.png";
import NewFooter from "../../NewFooter";
import { ThemeContext } from "../../context/ThemeContext";

function HealthCares() {
  const navigate = useNavigate();

  // const [healthCategories, setHealthCategories] = useState();
  const [loading, setLoading] = useState(false);
  const location = useLocation();
  const rawPath = location.pathname;
  const room_uuid = rawPath.split("/")[1];
  const euuid = rawPath.split("categories/")[1];
  // const new_design = localStorage.getItem("new_design");
  // const theme = new_design == "true";
  const { theme } = useContext(ThemeContext);
  console.log("rawPath", rawPath);

  // useEffect(() => {
  //   const getData = async () => {
  //     setLoading(true);
  //     axios
  //       .get(
  //         `/${room_uuid}/health_care_and_wellness/categories/1343f761-cf60-42eb-8117-df4748e286b1`
  //       )
  //       .then((res) => {
  //         const data = res.data.data;
  //         setHealthCategories(data.health_care_and_wellness_categories);
  //         console.log(data);
  //         setLoading(false);
  //       });
  //   };
  //   getData();
  // }, []);
  const lang = localStorage.getItem("lang");

  // setTimeout(() => {
  //   setEnableClass(false);
  // }, 3000);

  let flowers;
  let bgColor;
  let color;
  let text;
  let healthCategories;
  const { data, isLoading, error } = ShowHealthCares(room_uuid, euuid, lang);
  if (!isLoading) {
    if (data.data.status === false) {
      navigate(`/${room_uuid}/qr-scan`);
    } else {
      flowers = data.data.data;
      healthCategories = flowers.health_care_and_wellness_categories;
      color = flowers.color;
      bgColor = flowers.header_color;
      text = flowers.header_title;
      // if (entertainmentCategories?.length == 1) {
      //   navigate(
      //     `/${room_uuid}/entertainment/categories/${entertainmentCategories[0].uuid}`,
      //     { replace: true }
      //   );
      // }
      // console.log("ShowHealthCaresShowHealthCares", flowers);
    }
  }
  useEffect(() => {
    window.scrollTo({
      top: 0,
      left: 0,
    });
  }, []);

  return (
    <div className={theme ? "food pb-0" : ""}>
      {theme ? (
        <Title title={text} />
      ) : (
        <HeaderInner
          headerName={text}
          bgColor={bgColor}
          color={color}
        />
      )}

      <main className="home-main bg-white pt-3">
        <div className={theme ? "box_food" : ""}>
          {isLoading ? (
            <SpaLoader />
          ) : healthCategories ? (
            healthCategories.map((x, idx) => {
              return theme ? (
                <div key={idx}>
                  <Link to={`/${room_uuid}/HealthcareOrder/${x.uuid}`}>
                    <img src={x?.image} alt="" className="booking_img" />
                    <img src={Overllay} alt="" className="overlay" />
                    <div className="text_food">
                      <h5 className="">{x.name}</h5>
                    </div>{" "}
                  </Link>
                </div>
              ) : (
                <div id={x.name} key={idx}>
                  <Zoom>
                    <div
                      className="con-img-container"
                      onClick={() =>
                        navigate(`/${room_uuid}/HealthcareOrder/${x.uuid}`)
                      }
                    >
                      <div className="con-img helth_car">
                        <img src={x.image} alt="" />
                        <p></p>
                      </div>
                    </div>
                    <div className="con-text-container">
                      <p>{x.name}</p>
                      <hr className="mb-0 dark" />
                    </div>
                  </Zoom>
                </div>
              );
            })
          ) : null}
        </div>
      </main>
      {theme && <NewFooter />}
    </div>
  );
}
export default HealthCares;
