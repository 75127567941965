import React, { useContext, useState } from "react";
import { Button } from "react-bootstrap";
import { Link } from "react-router-dom";
import { useCartStore } from "../store/useStore";
import axios from "../axios";
import { toast } from "react-toastify";
import { useQueryClient } from "react-query";
import ImageSuperMarket from "./ImageSuperMarket";
import { ThemeContext } from "../context/ThemeContext";
import { t } from "i18next";

const CartItem = ({
  item,
  url_remove,
  url_decrease,
  url_increase,
  v2,
  order_type,
  setIsRemoved,
  serFirst

}) => {
  const [enableClass, setEnableClass] = useState(false);
  const [count, setCount] = useState(item.count);
  const [tempCount, setTempCount] = useState(0);
  const [drop, setDrop] = useState(false);
  const [loading, setLoading] = useState(false);
  const toggleDrop = () => {
    setDrop((prev) => (prev === true ? false : true));
  };
  // const new_design = localStorage.getItem("new_design");
  // const theme = new_design == "true";
  const { theme } = useContext(ThemeContext);
  const lang = localStorage.getItem("lang")
  const { cart, setCart } = useCartStore((state) => ({
    cart: state.cart,
    setCart: state.setCart,
  }));
  const queryClient = useQueryClient();
  const currency = localStorage.getItem("currency");

  // setTimeout(() => {
  //   setEnableClass(false);
  // }, 1000);
  const handleIncrease = (i) => {
    setLoading(true);
    serFirst(true)
    setIsRemoved(true);


    axios
      .post(url_increase, {
        id: item.id,
      })
      .then((res) => {
        setLoading(false);
        setIsRemoved(false);
        serFirst(true)
        queryClient.invalidateQueries(`checkout`);
        // const data = res.data.data;
        // if (res.data.status) {
        //   console.log("++++");
        //   setTempCount((prev) => prev + 1);
        // }
        // // setRestaurantItems(data.items);
        // // setHeaderName(data.title);
        // console.log("MY", res);
      });
  };
  const handleDecrease = () => {
    setLoading(true);
    serFirst(true)
    setIsRemoved(true);

    // setCount((prev) => prev + 1);
    // item.count++;
    axios
      .post(url_decrease, {
        id: item.id,
      })
      .then((res) => {
        setLoading(false);
        setIsRemoved(false);
        serFirst(true)

        queryClient.invalidateQueries(`checkout`);

        // const data = res.data.data;
        // if (res.data.status) {
        //    queryClient.invalidateQueries(`checkout`);
        //   setTempCount((prev) => {
        //     console.log("MY PREV --", prev);
        //     if (prev + item.quantity <= 1) {
        //       return prev;
        //     }
        //     return prev - 1;
        //   });
        // }
        // setRestaurantItems(data.items);
        // setHeaderName(data.title);
      });
  };
  const removeItem = async () => {
    setIsRemoved(true);
    serFirst(true)
    const tempItems = cart.filter((a) => item.id !== a.id);

    await axios
      .post(url_remove, {
        id: item.id,
      })
      .then((res) => {
        setIsRemoved(false);
        queryClient.invalidateQueries(`checkout`);

        if (theme) {


          toast.success("Item has been removed", {
            className: 'custom-toast',
            bodyClassName: 'custom-toast',
            progressClassName: 'custom-toast',
          });
        } else {
          toast.success("Item has been removed");
        }
        // const data = res.data.data;
        // if (res.data.status) {
        //   setIsRemoved(true);
        // }
        // // setRestaurantItems(data.items);
        // // setHeaderName(data.title);
        // console.log("MY", res);
      });
  };
  return (
    <>
      {true ? (
        v2 ? (
          <div className="add-post-container v2 mt-4">
            <div className="add-cart-post payment v2">
              <div className="add-cart-post-inner">
                <div
                  className={
                    enableClass ? "animated-background bg-skimmer" : ""
                  }
                ></div>
                <div className="cross-btn-red" onClick={removeItem}>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="25"
                    height="25"
                    viewBox="0 0 23 23"
                  >
                    <path
                      id="Icon_ionic-ios-close-circle"
                      data-name="Icon ionic-ios-close-circle"
                      d="M14.875,3.375a11.5,11.5,0,1,0,11.5,11.5A11.5,11.5,0,0,0,14.875,3.375Zm2.914,15.663-2.914-2.914-2.914,2.914a.884.884,0,1,1-1.25-1.25l2.914-2.914-2.914-2.914a.884.884,0,0,1,1.25-1.25l2.914,2.914,2.914-2.914a.884.884,0,0,1,1.25,1.25l-2.914,2.914,2.914,2.914a.888.888,0,0,1,0,1.25A.878.878,0,0,1,17.789,19.038Z"
                      transform="translate(-3.375 -3.375)"
                      fill="#f11027"
                    ></path>{" "}
                  </svg>
                </div>
                <Link to="#" className="img-pnl ">
                  {order_type == "supermarket_order" ? (
                    <ImageSuperMarket src={item.image} />
                  ) : (
                    <img src={item.image} alt="Post" />
                  )}
                </Link>
                <div className="txt-pnl">
                  <Link>
                    <p className="post-title">{item.name}</p>
                    {item?.option?.length > 0 && (
                      <div class="middel middel2">
                        <a onClick={toggleDrop} class="arrowdown">
                          {t("Extra")}
                        </a>

                        <div
                          className={drop ? "ex-d mystyle" : "ex-d"}
                          style={{ display: "none" }}
                        >
                          {item.option &&
                            item.option.map((extra) => {
                              return (
                                <p style={{ height: "unset" }}>{extra.name}</p>
                              );
                            })}
                          {item.text &&
                            Object.values(item.text).map((extra) => {
                              return <p style={{ height: "unset" }}>{extra}</p>;
                            })}
                        </div>
                      </div>
                    )}
                    {item?.text?.length > 0 && item.option.length == 0 ? (
                      <div class="middel middel2">
                        <a onClick={toggleDrop} class="arrowdown">
                          {t("Extra")}
                        </a>

                        <div
                          className={drop ? "ex-d mystyle" : "ex-d"}
                          style={{ display: "none" }}
                        >
                          {item.text &&
                            Object.values(item.text).map((extra) => {
                              return <p style={{ height: "unset" }}>{extra}</p>;
                            })}
                        </div>
                      </div>
                    ) : null}
                    {item?.items?.length > 0 && (
                      <div class="middel middel2">
                        <a onClick={toggleDrop} class="arrowdown">
                          {t("Extra")}
                        </a>

                        <div
                          className={drop ? "ex-d mystyle" : "ex-d"}
                          style={{ display: "none" }}
                        >
                          {item.items &&
                            item.items.map((extra) => {
                              return (
                                <p style={{ height: "unset" }}>{extra.name}</p>
                              );
                            })}
                          {item.text &&
                            Object.values(item.text).map((extra) => {
                              return <p style={{ height: "unset" }}>{extra}</p>;
                            })}
                        </div>
                      </div>
                    )}
                  </Link>
                  <div className="flex-div">
                    {order_type == "restaurant_order" ? (
                      item.show_prices == 1 ? (
                        <h3>
                          {currency} {parseFloat(item.price).toFixed(2)}
                        </h3>
                      ) : (
                        <h3></h3>
                      )
                    ) : (
                      <h3>
                        {currency} {parseFloat(item.price).toFixed(2)}
                      </h3>
                    )}

                    <div>
                      <Button className="reg-btn small">Add To Cart</Button>
                    </div>
                    <div className="count-order-amount">
                      <Button
                        className="small"
                        onClick={handleDecrease}
                        disabled={item.quantity + tempCount == 1 || loading}
                      >
                        <i className="fa fa-minus"></i>
                      </Button>
                      <p>{item.quantity + tempCount}</p>
                      <Button
                        className=" small"
                        onClick={handleIncrease}
                        disabled={loading}
                      >
                        <i className="fa fa-plus"></i>
                      </Button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        ) : (
          <div className="add-post-container v2 mt-4">
            <div className="add-cart-post payment v2">
              <div className="add-cart-post-inner">
                <div
                  className={
                    enableClass ? "animated-background bg-skimmer" : ""
                  }
                ></div>
                <div className="cross-btn-red" onClick={removeItem}>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="25"
                    height="25"
                    viewBox="0 0 23 23"
                  >
                    <path
                      id="Icon_ionic-ios-close-circle"
                      data-name="Icon ionic-ios-close-circle"
                      d="M14.875,3.375a11.5,11.5,0,1,0,11.5,11.5A11.5,11.5,0,0,0,14.875,3.375Zm2.914,15.663-2.914-2.914-2.914,2.914a.884.884,0,1,1-1.25-1.25l2.914-2.914-2.914-2.914a.884.884,0,0,1,1.25-1.25l2.914,2.914,2.914-2.914a.884.884,0,0,1,1.25,1.25l-2.914,2.914,2.914,2.914a.888.888,0,0,1,0,1.25A.878.878,0,0,1,17.789,19.038Z"
                      transform="translate(-3.375 -3.375)"
                      fill="#f11027"
                    ></path>{" "}
                  </svg>
                </div>
                <Link to="#" className="img-pnl ">
                  <img src={item.image} alt="Post" />
                </Link>
                <div className="txt-pnl">
                  <Link>
                    <p className="post-title">{item.name}</p>
                    {item.extras && (
                      <div class="middel middel2">
                        <a onClick={toggleDrop} class="arrowdown">
                          {t("Extra")}
                        </a>

                        <div
                          className={drop ? "ex-d mystyle" : "ex-d"}
                          style={{ display: "none" }}
                        >
                          {item.extrasWithOptions[0].option &&
                            item.extras.map((extra) => {
                              return (
                                <p style={{ height: "unset" }}>{extra.name}</p>
                              );
                            })}
                        </div>
                      </div>
                    )}
                  </Link>
                  <div className="flex-div">
                    <h3>
                      {currency} {item.price * item.count}
                    </h3>
                    <div>
                      <Button className="reg-btn small">Add To Cart</Button>
                    </div>
                    <div className="count-order-amount">
                      <Button className="small" onClick={handleIncrease}>
                        <i className="fa fa-minus"></i>
                      </Button>
                      <p>{count}</p>
                      <Button className=" small" onClick={handleDecrease}>
                        <i className="fa fa-plus"></i>
                      </Button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        )
      ) : null}
    </>
  );
};

export default CartItem;
