import React, { useEffect } from "react";
import Title from "../../new-components/title/Title";
import { Link, useLocation, useParams } from "react-router-dom";
import Overllay from "../../assets/new_imgs/Rectangle 1072.png";
import NewFooter from "../../NewFooter";
import "../food-and-beverages/FoodAndBeverages.scss";

const SpaAndWellness = () => {
  useEffect(() => {
    window.scrollTo({
      top: 0,
      left: 0,
    });
  }, []);
  const params = useParams();
  const lang = localStorage.getItem("lang");
  const location = useLocation();
  const metadata = location.state?.metadata;

  console.log("metadata", metadata);
  return (
    <div className={`food ${lang == "AR" && "arabic"}`}>
      <Title title={"Spa & Wellness"} />

      {/* <NewLoader /> */}
      <div className="box_food image_services">
        {metadata?.map((service) => {
          if (service.id == 4) {
            return (
              <Link
                to={`/${params.room_uuid}/hotel-services/spa/${
                  service.link.split(`${params.room_uuid}/spa/`)[1]
                }`}
              >
                <img
                  src={"https://d3l5wxnahfuscp.cloudfront.net/static/Spa.png"}
                  alt=""
                  className="service_img"
                />
                <img src={Overllay} alt="" className="overlay" />
                <h6 className="sub_title">{service?.display_name}</h6>
              </Link>
            );
          }
          if (service.id == 17) {
            return (
              <Link
                to={`/${params.room_uuid}/spa-wellness/health_care_and_wellness`}
              >
                <img
                  src={
                    "https://d3l5wxnahfuscp.cloudfront.net/static/healthcare.png"
                  }
                  alt=""
                  className="service_img"
                />
                <img src={Overllay} alt="" className="overlay" />
                <h6 className="sub_title">{service?.display_name}</h6>
              </Link>
            );
          }
        })}
      </div>
      <NewFooter />
    </div>
  );
};

export default SpaAndWellness;
