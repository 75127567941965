import React, { useEffect } from "react";
import { useLocation, useParams } from "react-router-dom";
import HeaderInner from "../../Components/HeaderInner";
import SubermarketComponent from "../../Components/SubermarketComponent";
import Title from "../../new-components/title/Title";
import NewFooter from "../../NewFooter";

function SupermarketCategories() {
  // const [parmacy, setParmacy] = useState({ id: "", uuid: "" });
  const location = useLocation();
  const params = useParams();
  const rawPath = location.pathname;
  const room_uuid = rawPath.split("/")[1];
  // const theme = location.state?.theme;
  const new_design = localStorage.getItem("new_design");
  const theme = new_design === "true";


  useEffect(() => {
    window.scrollTo({
      top: 0,
      left: 0,
    });
  }, []);
  // <HeaderInner headerName="SUPERMARKET" />
  return (
    <div className={theme ? "food" : ""}>
      {theme ? (
        <Title title={"SUPERMARKET"} />
      ) : (
        <HeaderInner headerName={"SUPERMARKET"} />
      )}
      <main className="home-main bg-white back-white pad-top-0">
        <>
          {
            <SubermarketComponent
              room_uuid={room_uuid}
              ph_uuid={params.supermarket_uuid}
              theme={theme}
            />
          }
        </>
      </main>
      {theme && <NewFooter />}
    </div>
  );
}
export default SupermarketCategories;
