// utils/customization.js

export const deepCloneAndMutate = (obj) => {
	if (Array.isArray(obj)) {
		return obj.map((item) => deepCloneAndMutate(item));
	} else if (typeof obj === "object" && obj !== null) {
		const newObj = { ...obj };

		if (newObj.hasOwnProperty("option")) {
			newObj.option = newObj.option.map((opt) => ({
				...opt,
				checked: false,
				quantity: 1,
				extrasWithOptions: deepCloneAndMutate(opt.extrasWithOptions),
			}));
		}

		if (newObj.hasOwnProperty("extrasWithOptions")) {
			newObj.extrasWithOptions = deepCloneAndMutate(
				newObj.extrasWithOptions
			);
		}

		return newObj;
	}

	return obj;
};

export const updateCheckedState = (extras, path, checked) => {
	if (!extras) return extras;

	const updatedExtras = extras.map((extra) => ({ ...extra }));

	const recursiveUpdate = (items, currentPath) => {
		const [extraIndex, optionIndex, ...remainingPath] = currentPath;
		if (extraIndex === undefined || optionIndex === undefined) return;

		let extra = items[extraIndex];
		if (!extra || !extra.option) return;

		let option = extra.option[optionIndex];
		if (!option) return;

		if (extra.extra_type_name === "radio") {
			extra.option.forEach((opt, idx) => {
				opt.checked = idx === optionIndex ? checked : false;
				if (!opt.checked && opt.extrasWithOptions) {
					uncheckNestedOptions(opt.extrasWithOptions);
				}
			});
		} else {
			option.checked = checked;
		}

		if (!checked && option.extrasWithOptions) {
			uncheckNestedOptions(option.extrasWithOptions);
		}

		if (remainingPath.length > 0) {
			recursiveUpdate(option.extrasWithOptions, remainingPath);
		}
	};

	const uncheckNestedOptions = (nestedExtras) => {
		nestedExtras.forEach((nestedExtra) => {
			nestedExtra.option.forEach((nestedOption) => {
				nestedOption.checked = false;
				if (nestedOption.extrasWithOptions) {
					uncheckNestedOptions(nestedOption.extrasWithOptions);
				}
			});
		});
	};

	recursiveUpdate(updatedExtras, path);

	return updatedExtras;
};

export const calculateTotalPrice = (extrasWithOptions) => {
	return extrasWithOptions.reduce((total, extra) => {
		if (
			extra.price &&
			extra.option &&
			extra.option.some((o) => o.checked)
		) {
			total += parseFloat(extra.price) || 0;
		}

		if (extra.option) {
			total += extra.option.reduce((optionTotal, option) => {
				if (option.checked) {
					optionTotal +=
						(parseFloat(option.price) || 0) *
						(option.quantity || 1);
				}

				if (option.extrasWithOptions) {
					optionTotal += calculateTotalPrice(
						option.extrasWithOptions
					);
				}

				return optionTotal;
			}, 0);
		}

		if (extra.extrasWithOptions) {
			total += calculateTotalPrice(extra.extrasWithOptions);
		}

		return total;
	}, 0);
};

export const collectSelectedExtras = (extrasWithOptions) => {
	return extrasWithOptions.reduce((acc, extra) => {
		const selectedOptions = extra.option.filter((opt) => opt.checked);

		selectedOptions.forEach((option) => {
			const optionData = {
				extra_id: extra.extra_id,
				option_id: option.id,
				type: option.type,
				quantity: option.quantity,
				extras: collectSelectedExtras(option.extrasWithOptions || []),
			};

			acc.push(optionData);
		});

		return acc;
	}, []);
};

export const updateNestedOptionsCount = (extras, path, count, depth = 0) => {
	if (!extras || depth >= path.length) return extras; // Stop if extras is empty or we've gone too deep

	return extras.map((extra, extraIndex) => {
		if (extraIndex !== path[depth]) return extra; // Skip if not the matching index

		// If we reach the final index in the path, update the quantity of the option
		if (depth === path.length - 1) {
			const newQuantity = (extra.quantity || 0) + count;
			return {
				...extra,
				quantity: Math.max(1, newQuantity), // Increase or decrease quantity, Ensure quantity is at least 1
			};
		}

		// Recursively update inside options or extrasWithOptions
		return {
			...extra,
			option: extra.option
				? updateNestedOptionsCount(extra.option, path, count, depth + 1)
				: extra.option,
			extrasWithOptions: extra.extrasWithOptions
				? updateNestedOptionsCount(
						extra.extrasWithOptions,
						path,
						count,
						depth + 1
				  )
				: extra.extrasWithOptions,
		};
	});
};
export function checkExtras(extras) {
	for (const extra of extras) {
		if (extra.is_required === 1) {
			// Ensure at least one option is checked
			const hasCheckedOption = extra.option.some(
				(option) => option.checked
			);

			if (!hasCheckedOption) {
				return false; // Validation failed
			}
		}

		// Recursively validate sub-extras inside options
		for (const option of extra.option) {
			if (
				option.extrasWithOptions &&
				!checkExtras(option.extrasWithOptions)
			) {
				return false; // Validation failed in sub-extra
			}
		}
	}
	return true; // Validation passed
}
