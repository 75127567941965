import React, { useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { Zoom } from "react-reveal";
import HeaderInner from "../../Components/HeaderInner";
import SpaLoader from "../../Components/Loaders/SpaLoader";
import { ShowSupermarkets } from "../../API/Supermarket";

function Supermarket() {
  // const [parmacy, setParmacy] = useState({ id: "", uuid: "" });
  const location = useLocation();
  const rawPath = location.pathname;
  const room_uuid = rawPath.split("/")[1];

  const navigate = useNavigate();
  const lang = localStorage.getItem("lang");

  let supermarket = { id: "", uuid: "" };
  let supermarkets_;
  let supermarkets;
  let bgColor;
  let color;
  let text;
  const { data, isLoading, error } = ShowSupermarkets(room_uuid, lang);
  if (!isLoading) {
    if (error) {
      if (error?.response.status === 404) {
        navigate(`/not-found`);
      }
    } else {
      if (data.data.status === false) {
        navigate(`/${room_uuid}/qr-scan`);
      } else {
        supermarkets_ = data.data.data;
        supermarkets = supermarkets_.supermarkets;

        if (supermarkets.length > 0) {
          supermarket.id = supermarkets_.supermarkets[0].id;
          supermarket.uuid = supermarkets_.supermarkets[0].uuid;
        }
        color = supermarkets_.color;
        bgColor = supermarkets_.header_color;
        text = supermarkets_.title;
        // console.log("ShowSup", supermarkets_);
        if (supermarkets?.length === 1) {
          navigate(`/${room_uuid}/supermarket/${supermarkets[0].uuid}`, {
            replace: true,
          });
        }
      }
    }
  }

  useEffect(() => {
    window.scrollTo({
      top: 0,
      left: 0,
    });
  }, []);
  const showData = () => {
    if (isLoading) {
      return <SpaLoader />;
    } else {
      return supermarkets?.length > 1
        ? supermarkets?.map((supermarket, idx) => {
          return (
            <div key={idx}>
              <Zoom>
                <div
                  className="con-img-container v3"
                  onClick={() =>
                    navigate(`/${room_uuid}/supermarket/${supermarket.uuid}`)
                  }
                >
                  <div className="con-img">
                    <img src={supermarket.image} alt="" />
                    <p></p>
                  </div>
                </div>
                <div className="con-text-container">
                  <p>{supermarket.name}</p>
                  <hr className="mb-0 dark" />
                </div>
              </Zoom>
            </div>
          );
        })
        : null;
    }
  };
  return (
    <>
      <HeaderInner headerName={text} bgColor={bgColor} color={color} />
      <main className="home-main bg-white back-white pad-top-0">
        {showData()}
      </main>
    </>
  );
}
export default Supermarket;
