import "./MubadalaWifiInstructions.css"; // Import the CSS file for styling

export default function PrintDetails() {
	return (
		<div className="mubadala-container">
			<div className="mubadala-content">
				<h2>USING MUBADALA ACCOUNT:</h2>
				<ol className="mubadala-steps">
					<li>
						Open a new email in Mubadala Outlook addressed to{" "}
						<strong>mobileprinting@mubadala.ae</strong>.
					</li>
					<li>Attach a file that needs to be printed and sent.</li>
					<li>Login to printer.</li>
					<li>
						The respective document is available in the queue ready
						to be printed.
					</li>
				</ol>

				<h2>USING NON-MUBADALA ACCOUNT:</h2>
				<ol className="mubadala-steps">
					<li>
						Open a new email in any account addressed to{" "}
						<strong>mobileprinting@mubadala.ae</strong>.
					</li>
					<li>Attach a file that needs to be printed and sent.</li>
					<li>You will receive a pin code in your account inbox.</li>
					<li>Login to printer using the pin code.</li>
					<li>
						The respective document is available in the queue ready
						to be printed.
					</li>
				</ol>
			</div>
		</div>
	);
}
