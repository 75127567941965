import React, { useContext, useEffect } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import HeaderInner from "../../Components/HeaderInner";
import SpaLoader from "../../Components/Loaders/SpaLoader";
import { Zoom } from "react-reveal";
import { ShowEntertainments } from "../../API/EntertainmentAPI";
import { ShowLuxurioss } from "../../API/LuxuriosAPI";
import Title from "../../new-components/title/Title";
import NewFooter from "../../NewFooter";
import Overllay from "../../assets/new_imgs/Rectangle 1072.png";
import { ThemeContext } from "../../context/ThemeContext";

function LuxuriossCategories() {
  const location = useLocation();
  const params = useParams();
  const rawPath = location.pathname;
  const room_uuid = rawPath.split("/")[1];
  const euuid = rawPath.split("categories/")[1];
  const navigate = useNavigate();
  const luxurios_id = params.id
  console.log("luxurios_id", luxurios_id);
  const lang = localStorage.getItem("lang");
  // const new_design = localStorage.getItem("new_design");
  // const theme = new_design == "true";
  const { theme } = useContext(ThemeContext);
  let flowers;
  let bgColor;
  let color;
  let text;
  let entertainmentCategories;
  const { data, isLoading, error } = ShowLuxurioss(room_uuid, params.uuid, lang);
  if (!isLoading) {
    if (error) {
      if (error?.response.status == 404) {
        navigate(`/not-found`);
      }
    } else {
      if (data.data.status === false) {
        navigate(`/${room_uuid}/qr-scan`);
      } else {
        flowers = data.data.data;
        console.log(" flowers.flowers", flowers.luxurios_services);
        const filter = Object.values(flowers.luxurios_services)?.find((item) => item.id == params.id)
        console.log("ShowEntertainmentss", filter);

        entertainmentCategories = filter?.categories;
        color = flowers.color;
        bgColor = flowers.header_color;
        text = flowers.title;

      }
    }
  }

  // useEffect(() => {
  //   const getData = async () => {
  //     setLoading(true)
  //     axios
  //       .get(
  //         `/${room_uuid}/entertainment/categories/1bb893d8-e5b0-42ba-a684-7f563c2e8ca5`
  //       )
  //       .then((res) => {
  //         const data = res.data.data;
  //         setEntertainmentCategories(data.entertainment_categories);
  //         setTitle(data?.title)
  //         console.log("data,entertainment/companies", data);
  //         setLoading(false)
  //       });
  //   };
  //   getData();
  // }, []);
  useEffect(() => {
    window.scrollTo({
      top: 0,
      left: 0,
    });
  }, []);

  return (
    <div className={theme ? "food pb-4" : ""}>
      {theme ? <Title title={text} /> : <HeaderInner headerName={text} />}
      <main className="home-main bg-white pt-3">
        <div className="">
          {isLoading ? (
            <SpaLoader />
          ) : entertainmentCategories ? (
            theme ?
              <div className="box_food">
                {entertainmentCategories?.map((item, idx) => {
                  return (
                    <span
                      key={idx}
                      onClick={() =>
                        navigate(
                          `/${room_uuid}/luxurios-detail/${item.uuid}`,
                          {
                            state: { theme: true },
                          }
                        )
                      }
                    >
                      <div style={{ position: "relative", marginBottom: "25px" }}>
                        <img src={item?.image} alt="" className="booking_img" />
                        <img src={Overllay} alt="" className="overlay" />
                        <div className="text_food">
                          <h5 className="">{item.name}</h5>
                        </div>{" "}
                      </div>
                    </span>
                  );
                })}
              </div>
              :
              entertainmentCategories.map((x, idx) => {
                return (
                  <div id={x.name} key={idx}>
                    <Zoom>
                      <div
                        className="con-img-container v3"
                        onClick={() =>
                          navigate(`/${room_uuid}/luxurios-detail/${x.uuid}`)
                        }
                      >
                        <div className="con-img">
                          <img src={x.image} alt="" />
                          <p></p>
                        </div>
                      </div>
                      <div className="con-text-container">
                        <p>{x.name}</p>
                        <hr className="mb-0 dark" />
                      </div>
                    </Zoom>
                  </div>
                );
              })
          ) : null}
        </div>
      </main>
      {theme && <NewFooter />}

    </div>
  );
}
export default LuxuriossCategories;
