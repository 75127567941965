import React, { useContext, useEffect, useRef, useState } from "react";
import HeaderInner from "../Components/HeaderInner";
import { Link, useLocation, useNavigate } from "react-router-dom";
import axios from "../axios";
import SpaLoader from "../Components/Loaders/SpaLoader";
import Iframe from "react-iframe";
import Title from "../new-components/title/Title";
import NewFooter from "../NewFooter";
import { Zoom } from "react-reveal";
import { ThemeContext } from "../context/ThemeContext";

function ThingsToDo() {
  const navigate = useNavigate();
  const [loading, setLaoding] = useState(false);

  const [newspaper, setNewspaper] = useState([]);
  const [title, setTitle] = useState("");

  const location = useLocation();
  const rawPath = location.pathname;
  const room_uuid = rawPath.split("/")[1];
  // const new_design = localStorage.getItem("new_design");
  // const theme = new_design == "true";

  const { theme } = useContext(ThemeContext);

  const lang = localStorage.getItem("lang")

  useEffect(() => {
    window.scrollTo({
      top: 0,
      left: 0,
    });
  }, []);

  useEffect(() => {
    const getData = async () => {
      setLaoding(true);
      axios
        .get(`/${room_uuid}/things-to-do`, {
          headers: {
            lang: lang
          }
        })
        .then((res) => {
          if (res.data.status === false) {
            navigate(`/${room_uuid}/qr-scan`);
          } else {
            const data = res.data.data;
            console.log("ress", res);
            setNewspaper(data?.newspaper)
            setTitle(data?.title)
            setLaoding(false);
          }
        })
        .catch((err) => {
          if (err?.response.status == 404) {
            navigate(`/not-found`);
          }
        });
    };
    getData();
  }, []);

  // <HeaderInner headerName={"Taxi booking"} />
  return (
    <div className={theme ? "food" : ""}>
      {theme ? (
        <Title title={title} />
      ) : (
        <HeaderInner headerName={title} />
      )}
      <main className="home-main bg-white back-white book-table pad-top-0 taxi_book thing_to">
        {loading ? (
          <SpaLoader />
        ) : (
          <ul
            className={
              loading ? "animated-background bg-skimmer menu-list" : "menu-list"
            }
          >
            {newspaper?.map((service, idx) => {
              return (
                <li
                  key={idx}

                >
                  <Zoom>
                    <Link
                      id="trans-anime"
                      className={`trans closed ${service.is_closed && "services-option"
                        } `}
                      // to={routes[service.service_name]}
                      // to={service?.link}
                      to={`/${room_uuid}/things-to-do-item?url=${service?.link}&title=${service.name}`}
                    >
                      <div className="img-pnl  position-relative ">
                        <div className="closed-tag"></div>
                        <img
                          src={service.icon}
                          className=""
                          alt="In-Room Dining"
                        />
                        <div

                          style={{
                            borderRadius: "50%",
                            left: "-5px",
                            top: "-5px",
                            bottom: "-5px",
                            right: "-5px",
                          }}
                        ></div>
                      </div>
                      {/* <div className="txt-pnl position-relative">
                        <div

                        ></div>

                        <p className="">
                          {service.name}
                        </p>
                      </div> */}
                    </Link>
                  </Zoom>
                </li>
              );
            })}
          </ul>

        )
        }
      </main>
      {theme && <NewFooter />}
    </div>
  );
}
export default ThingsToDo;
